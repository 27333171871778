import axios from "axios";
import { SupportedMethods, urlsList } from "./UrlsList";
import { useSelector } from "react-redux";
import { InitialProfilesState, SetTokenAndRefreshToken, SelectedProfile } from "../store/profileStateSlice";
import { Navigate, useNavigate } from "react-router-dom";
import links from "../config/links";
import { setTokenSourceMapRange } from "typescript";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";



// import config from "../config/config";
// import { UserInfoProperties } from "../interfaces/auth/AuthLoginStructure";
// import { SupportedMethods, endpoints } from "./Endpoint";

/**
 * @class Api
 * @param {string} _token as private user JWT
 * @param {string} _refreshToken as alternative user JWT
 */

/**
 * Request Handler method
 * @param {string} path
 * @param {string} method
 * @param {object} data
 * @param {necessary} boolean
 * @returns Response of data or errors.
 */

export const RequestHandler = async (
    dispatch: Dispatch<AnyAction>,
    path: string = "",
    method: SupportedMethods,
    necessary: boolean,
    data?: object,
    selectorProfile?: InitialProfilesState,
    isFileInclude?: boolean,
    // criterion?: (data : any) => boolean,// (default: data=>true)
    // exception: instanceof Error, // default=Error
) => {
    let userData = selectorProfile;
    if (necessary) {
        if (userData == undefined) {
            throw Error("why the userData is null :(");
        }
        else if (userData.lastUpdatePosixTime < 0) {
            throw Error("not user exist in store :(");
        }


    }

    // Define headers like here.
    if (necessary && userData) {
        console.log("token:) for send request");
        console.log(userData.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${userData.token}`;
    }
    if (isFileInclude ?? false) {
        axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    }
    else {
        axios.defaults.headers.common["Content-Type"] = "application/json";
    }

    // Prepare response
    //   const response = await axios[method](`${config.serverIP}${path}`, data);
    const responseive = await axios[method](path, data)
        .then((response: any) => {
            console.log("response api :)");
            console.log(response.data);
            return response.data;
        })
        .catch(async (error: any) => {
            console.log("error in api:(");
            console.log(error);
            let errorData = {
                status: 0,
                data: null,
            };

            if (error.response) {
                errorData = {
                    status: error.response.status,
                    data: error.response.data,
                };
            } else if (error.request) {
                errorData = {
                    ...errorData,
                    data: error.request,
                };
            } else {
                errorData = {
                    ...errorData,
                    data: error.message,
                };
            }

            // Handle unauthorized status code 401
            // console.log("error data:")
            // console.log(errorData);
            if (errorData.status === 401) {
                // console.log(errorData.status);
                if (selectorProfile) {
                    if (selectorProfile.refreshToken.length > 1) {
                        let res = await _refreshAccessToken(dispatch, selectorProfile, path.includes("/en/"));
                        throw res;
                    }
                    else {
                        throw errorData;
                    }
                }
                else {
                    dispatch(SetTokenAndRefreshToken({ token: "", refreshToken: "", lastUpdatePosixTime: -1 }));
                    throw errorData;
                }
            }
            else {
                throw errorData;
            }

            // if custom criteria in the inputs ((data)=>criteria, exception=Error), failed: throw exception, else return data.
            // console.log("omad:)");


        });
    console.log("what ?:)");
    console.log(responseive)
    return responseive;

    // } catch (error: any) {
    //     // Define Error object to error handle of view
    //     console.log("error in api2:(");
    //     let errorData = {
    //         status: 0,
    //         data: null,
    //     };

    //     if (error.response) {
    //         errorData = {
    //             status: error.response.status,
    //             data: error.response.data,
    //         };
    //     } else if (error.request) {
    //         errorData = {
    //             ...errorData,
    //             data: error.request,
    //         };
    //     } else {
    //         errorData = {
    //             ...errorData,
    //             data: error.message,
    //         };
    //     }

    //     // Handle unauthorized status code 401
    //     if (errorData.status === 401) {
    //         // console.log(errorData.status);
    //         _refreshAccessToken();
    //     }

    //     // if custom criteria in the inputs ((data)=>criteria, exception=Error), failed: throw exception, else return data.
    //     return errorData;
    // }
};

const _refreshAccessToken = async (dispatch: Dispatch<AnyAction>,
    selectorProfile: InitialProfilesState, isRequestEN: boolean) => {
    // const dispatch = useDispatch();
    /// This function will work when we get error related to refresh
    /// your token.
    /// In local storage we have accessToken and refreshToken.
    /// Getting refresh token from refresh token endpoint
    /// by passing refresh token to Authorization header.
    /// Will response two tokens: accessToken and refreshToken.
    /// During try to refresh token if i get this response i will
    /// logout user by clear LocalStorage (status will be 401):
    /// {
    ///   "status": 401,
    ///   "data": {
    ///     "success": false,
    ///     "errors": {
    ///       "token": [
    ///         "Given token not valid for any token type"
    ///       ]
    ///     }
    ///   }
    /// }
    /// If i get valid access from refreshToken endpoint,
    /// I will get object of local user data from LocalStorage
    let userData = selectorProfile;
    console.log("refresh token:")
    if (userData == undefined)
        return;

    try {
        let refreshEndpoint = urlsList.auth.refreshToken;


        const response: any = await RequestHandler(
            dispatch,
            isRequestEN ? "/en" + refreshEndpoint.url : "/fa" + refreshEndpoint.url,
            refreshEndpoint.method,
            refreshEndpoint.isTokenNecessary,
            { refresh: userData.refreshToken }
        );
        console.log("refresh token response:")
        console.log(response)

        const renewToken = response.data.access;
        const renewRefreshToken = response.data.refresh;
        if (renewToken) {
            // After get access token we will update LocalStorage accessToken value.
            // And reload view to get new access token from LocalStorage.
            userData.token = renewToken;
            userData.refreshToken = renewRefreshToken;
            dispatch(SetTokenAndRefreshToken(userData));
        } else {
            dispatch(SetTokenAndRefreshToken({ token: "", refreshToken: "", lastUpdatePosixTime: -1 }));
        }
    } catch (e) {
        dispatch(SetTokenAndRefreshToken({ token: "", refreshToken: "", lastUpdatePosixTime: -1 }));
    }
};

