import colors from "../../config/colors";
import { interfaceEmptyList } from "./interfaceEmptyList";

function EmptyList(componentsParams: interfaceEmptyList) {
    return (


        <div style={{
            width: "100%",
            border: `2px solid ${colors.theme.green.dark40}`, borderRadius: "8px",
            boxSizing: "border-box", display: "flex", alignItems: "center", justifyContent: "center",
            paddingInline: "3vw", 
            paddingBlock: "calc(1vh + 12px)",
            marginBlock: "calc(2vh + 24px)",

        }}>
            <h5 style={{ color: colors.theme.blue.dark80 }}>{componentsParams.text}</h5>
        </div>
    )
}
export default EmptyList;