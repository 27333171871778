import { styled as styledMUI } from "@mui/material/styles";
import { Card, Select } from "@mui/material";

export const SelectFromMUI = styledMUI(Select)(({ theme }) => ({
    fontFamily: "vazirmatn-Regular",
    // fontSize: "1px !important",
    "& svg": {
        left: theme.direction == "rtl" ? "7px" : "unset",
        right: theme.direction == "rtl" ? "unset" : "7px",
    },
    "& div": {
        paddingRight: theme.direction == "rtl" ? "14px !important" : "32px !important",
        paddingLeft: theme.direction == "rtl" ? "32px" : "14px",
    },
    "& legend": {
        textAlign: theme.direction == "rtl" ? "right" : "left",


    },
}));