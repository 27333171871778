import { useState } from "react";
import { CardFromMUI } from "../../MUIConfig/CardFromMUI";
import { Button, CardContent, Divider, FormControl, InputLabel, Theme } from "@mui/material";
import colors from "../../../config/colors";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";
import Box from "@mui/material/Box";
import { SelectFromMUI } from "../../MUIConfig/SelectFromMUI";
import { MenuItemFromMUI } from "../../MUIConfig/MenuItemFromMUI";
import { ConfigComps } from "../../../config/ConfigCOMP";

import { styled as styledMUI } from "@mui/material/styles";
import { PanoramaFishEyeOutlined, VisibilityOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { InterfaceProductTableTitle, InterfaceProductTableTitles } from "./InterfaceProductTableTitles";

const TitleP = styledMUI("th", { shouldForwardProp: (prop) => prop !== 'isBorderActive' && prop !== 'width' })<{ isBorderActive: boolean; width: string|undefined; }>(({
    theme,
    isBorderActive,
    width
}) => ({
    whiteSpace: "nowrap",
    fontWeight:"bolder",
    fontSize:"12px",
    width: width,
    padding:isBorderActive?"8px":undefined,
    border: isBorderActive ? `1px solid #d2d2d2` : undefined
}));


const ProductTableTitle = (paramsPage: InterfaceProductTableTitles) => {
    return (
        <thead >
            <tr>
                {paramsPage.titlesObj.map((value, index, array) => {
                    return (
                        <TitleP key={index} isBorderActive={value.isBorderActive} width={value.width}>{value.name}</TitleP>
                    )
                })}


            </tr>
        </thead>
    );

}

export default ProductTableTitle;