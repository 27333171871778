import { ReactNode, Children, Component, useState, ChangeEvent, HtmlHTMLAttributes, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InterfaceSelectAccessories, interfaceProductForAccessories } from "./InterfaceSelectAccessories";
import { styled as styledMUI, useTheme } from "@mui/material/styles";
import { ConfigComps } from "/Users/alirezasafafard/Documents/niktech/kheradazma/product_page_front/my-app/src/config/ConfigCOMP"
import { Link, useNavigate } from "react-router-dom";
import colors from "../../../config/colors";
import { Button, Card, Checkbox, FormControlLabel, Grid, Pagination } from "@mui/material";
import { GridFromMUI } from "../../../components/MUIConfig/GridFromMUI";
import { AddRounded, CircleNotificationsOutlined, CircleOutlined, CircleRounded, RemoveRounded } from "@mui/icons-material";
import { ButtonFromMUI } from "../../../components/MUIConfig/ButtonFromMUI";
import ProductTableTitle from "../../../components/products/list_processes/ProductTableTitle";
import ProductTableItem from "../../../components/products/list_processes/ProductTableItem";
import { NavbarMainPage } from "../../../components/navbarMain/NavbarMainPage";
import { TextFieldFromMUI } from "../../../components/MUIConfig/TextFieldFromMUI";
import { isVisibilityProgressBar } from "../../../store/ProgressbarInRootSlice";
import { RequestHandler } from "../../../api/API";
import { useDispatch, useSelector } from "react-redux";
import { HandlerLangUrl, urlsList } from "../../../api/UrlsList";
import { ShowAlert } from "../../../store/AlertStateModeSlice";
import { enumTypeOfAlert } from "../../../components/MUIConfig/alert_popup/interfaceAlertPopup";
import { SelectedProfile } from "../../../store/profileStateSlice";
import { interfaceResponseErrorFromServer, isAnySimilarInterfaceResponseErrorFromServer } from "../../../api/APIErrorResponse";
import SideBarProductDetails from "../../../components/AppBarMenu/sideBarProductDetails/SideBarProductDetails";
import { HelperResponsive } from "../../../helper/Helper";
import { ImageSquare } from "../../../components/imageSquare/ImageSquare";
const Header = styledMUI("div")(({ theme }) => ({
  background: "linear-gradient(to bottom, #3F51B5, #1E8CE4)",
  // background: "linear-gradient(to bottom, #3FB5A1, #1EE462)",
  width: "100%",
  color: colors.screens.color,
  paddingTop: "calc(7.5rem + 6vw)",
  paddingBottom: "calc(3rem + 2vh)",
  display: "flex",
  backgroundColor: "red",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundPosition: "80% 0",
  backgroundSize: "cover",
  position: "relative"
}));
const ImageOfBackgroundHeader = styledMUI("div")(({ theme }) => ({
  display: "block",
  width: "100%",
  height: "100%",
  zIndex: "1",
  opacity: "0.3",
  background: `url(${ConfigComps().screens.product.massSpectrometry.header.backgroundImage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "80% 0",
  backgroundSize: "cover",
  position: "absolute",
  top: 0,
  right: 0,
}))
const ImageOfBackgroundFooter = styledMUI("div")(({ theme }) => ({
  display: "block",
  width: "100%",
  height: "100%",
  zIndex: "1",
  opacity: "0.98",
  background: `url(${ConfigComps().screens.product.footer.backgroundImage})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "80% 0",
  backgroundSize: "cover",
  position: "absolute",
  top: 0,
  right: 0,
}))
const Main = styledMUI("div")(({ theme }) => ({
  marginTop: "calc(1vh + 1rem)",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "center",
  zIndex: "2"

}));
const ButtonSelectBondedSilica = styledMUI(ButtonFromMUI, { shouldForwardProp: (prop) => prop !== 'isSelectedBondedSlica', })<{ isSelectedBondedSlica: boolean; }>(({
  theme,
  isSelectedBondedSlica
}) => ({
  width: "100%",
  fontSize: "14px",
  borderRadius: "0",
  boxShadow: "none",
  border: `1px solid ${colors.green.dark}`,
  background: isSelectedBondedSlica ? colors.green.dark : colors.screens.color,
  color: !isSelectedBondedSlica ? colors.green.dark : colors.screens.color,
  '&:hover': {
    background: isSelectedBondedSlica ? colors.green.dark : colors.screens.color,
    color: !isSelectedBondedSlica ? colors.green.dark : colors.screens.color,
    boxShadow: "none",
  }

}));
const ButtonSelectBondedItemSilica = styledMUI(ButtonFromMUI, { shouldForwardProp: (prop) => prop !== 'isSelected', })<{ isSelected: boolean; }>(({
  theme,
  isSelected
}) => ({
  fontSize: "14px !important",
  borderRadius: "37px",
  textTransform: "none",
  padding: "4px 8px",
  width: "100%",
  height: "37px",
  boxShadow: "none",
  border: `1px solid ${colors.green.dark}`,
  background: isSelected ? colors.green.dark : colors.screens.color,
  color: !isSelected ? colors.green.dark : colors.screens.color,
  '&:hover': {
    background: isSelected ? colors.green.dark : colors.screens.color,
    color: !isSelected ? colors.green.dark : colors.screens.color,
    boxShadow: "none",
  }

}));
const ButtonFormSend = styledMUI(ButtonFromMUI)(({
  theme
}) => ({
  fontSize: "14px !important",
  borderRadius: "37px",
  color: colors.screens.product.main.tableProductSelected.form.button.color,
  background: colors.green.dark2,
  textTransform: "none",
  height: "37px",
  boxShadow: "none",
  padding: "1em 5em",
  '&:hover': {
    background: colors.screens.product.main.tableProductSelected.form.button.hoverBackground,

    boxShadow: "none",
  }

}));

const ShadowButtonProducts = styledMUI("div")(({ theme }) => ({

  position: "absolute",
  top: "0",
  left: "0",
  pointerEvents: "none",

  width: "120%",
  height: "101%",
  zIndex: "-1",
  transformOrigin: "0% 100%",
  transform: "rotate3d(0, 0, 1, -120deg) translate3d(0, -3em, 0)",
  transition: "transform 0.3s, opacity 0.3s, background-color 0.3s",
}));
const DivCardSubmit = styledMUI("div")(({ theme }) => ({
  width: "calc(100% - 44px)", display: "flex", flexDirection: "column", border: "1px solid #d2d2d2",
  boxSizing: "border-box",
  alignSelf: "end",
  boxShadow: colors.shadow.boxShadowDarkInTopAndBottom,
  [theme.breakpoints.down('md')]: {
    alignSelf: "center",
    width: "100%"
  },

  padding: "38px 26px 23px",
  marginBottom: "30px"
}))
const ButtonProducts = styledMUI(Button)(({ theme }) => ({
  color: colors.screens.color,
  pointerEvents: "auto",
  borderRadius: "2em",
  padding: "0.5rem 3rem",
  marginBottom: "18px",
  textAlign: "center",
  border: "1px solid",
  fontSize: "14px",
  overflow: "hidden",
  position: "relative",
  zIndex: "2",
  '&:hover': {
    color: colors.green.dark2,
    'div': {
      position: "absolute",
      top: "0",
      left: "0",
      width: "120%",
      height: "100%",
      background: "#fff",
      zIndex: "-1",
      transform: "rotate(0deg) translate3d(0, 0em, 0)",
    }
  }
}));
const ButtonAddBasket = styledMUI(Button, { shouldForwardProp: (prop) => prop !== 'isSelected' })<{ isSelected: boolean; }>(({
  theme,
  isSelected
}) => {
  return ({
    color: "white",
    background: isSelected ? colors.theme.pink.dark40 : colors.theme.blue.dark80,
    pointerEvents: "auto",
    borderRadius: "2em",
    padding: "0.5rem 2rem",
    marginBottom: "18px",
    textAlign: "center",
    border: "1px solid",
    fontSize: "14px",
    overflow: "hidden",
    position: "relative",
    zIndex: "2",
    "& span": {
      marginRight: "8px",
    },
    boxShadow: colors.shadow.boxShadowDarkInBottom,
    '&:hover': {
      boxShadow: "none",
      color: isSelected ? colors.theme.pink.dark40 : colors.theme.blue.dark80,
      'div': {
        position: "absolute",
        top: "0",
        left: "0",
        width: "120%",
        height: "100%",
        background: "#fff",
        zIndex: "-1",
        transform: "rotate(0deg) translate3d(0, 0em, 0)",
      }
    }
  })
});
const ButtonDownloadBrochure = styledMUI(Button)(({ theme }) => ({
  color: colors.screens.color,
  pointerEvents: "auto",
  borderRadius: "2em",
  padding: "0.5rem 3rem",
  margin: "0",
  textAlign: "center",
  border: "1px solid",
  fontSize: "14px",
  overflow: "hidden",
  position: "relative",
  zIndex: "2",
  background: "#93CED8",
  '&:hover': {
    color: colors.green.dark2,
    'div': {
      position: "absolute",
      top: "0",
      left: "0",
      width: "120%",
      height: "100%",
      background: "#fff",
      zIndex: "-1",
      transform: "rotate(0deg) translate3d(0, 0em, 0)",
    }
  }
}));
export const SelectAccessories = (paramsPage: InterfaceSelectAccessories) => {
  const navigate = useNavigate();
  const mTheme = useTheme();
  const { t, i18n } = useTranslation();
  var confComps = ConfigComps();
  const [getBondedCardView, setBondedCardView] = useState<boolean>(false);
  const [getFormName, setFormName] = useState<string>("");
  const [getFormCompany, setFormCompany] = useState<string>("");
  const [getFormEmail, setFormEmail] = useState<string>("");
  const [getFormCountry, setFormCountry] = useState<string>("");
  const [getFormMessage, setFormMessage] = useState<string>("");
  const [getProductsList, setProductsList] = useState<interfaceProductForAccessories[]>(confComps.screens.product.accessories.main.product.bodyTemp);
  const dispatch = useDispatch();
  const profileInStore = useSelector(SelectedProfile);

  const handleOnClickBasketOfProduct = (indexServer: number) => {
    var oldProductList = [...getProductsList]; // copying the old products array

    let index = oldProductList.findIndex(x => x.info.id == indexServer)
    oldProductList[index].isSelected = !oldProductList[index].isSelected
    setProductsList(oldProductList);
  }
  const handleOnClickSubmitForm = async () => {
    dispatch(isVisibilityProgressBar(true));
    const objectForPost = {
      name: getFormName,
      email: getFormEmail,
      company: getFormCompany,
      country: getFormCountry,
      message: getFormMessage,
      products: getProductsList.filter(product => product.isSelected == true).map((value, index, array) => {
        return (value.info.id)
      }),
    };
    await RequestHandler(dispatch, HandlerLangUrl(urlsList.product.postOrderProduct.url, mTheme),
      urlsList.product.postOrderProduct.method,
      urlsList.product.postOrderProduct.isTokenNecessary, objectForPost,
      profileInStore)
      .then((response: any) => {
        console.log("response:):)");
        console.log(response);
        dispatch(isVisibilityProgressBar(false));
        dispatch(ShowAlert({ text: "we will contact you.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
      })
      .catch((e: Error) => {
        console.log("response:(");

        console.log(e);
        dispatch(isVisibilityProgressBar(false));
        if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
          dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
        }
        else {
          dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
        }
      });
  }
  // const requestForGetProducts = async () => {
  //   dispatch(isVisibilityProgressBar(true));

  //   await RequestHandler(dispatch, HandlerLangUrl(urlsList.product.getProductList.url, mTheme),
  //     urlsList.product.getProductList.method,
  //     urlsList.product.getProductList.isTokenNecessary, {
  //     "pore_size": [],
  //     "particle_size": []
  //   },
  //     profileInStore)
  //     .then((response: any) => {
  //       console.log("response:):)");
  //       console.log(response);
  //       let res = response as interfaceProductForMassSpectrometry[];
  //       console.log(res);
  //       setProductsList(
  //         res.product.map((value, index, array) => {
  //           console.log(value)
  //           return (
  //             {
  //               "isSelected": false,
  //               "id": value.id,
  //               "product_name": value.product_name,
  //               "pdf_catalog": value.pdf_catalog,
  //               "product_description": value.product_description,
  //               "pore_diameter": value.pore_diameter,
  //               "surface_area": value.surface_area,
  //               "sio2": value.sio2,
  //               "capping_treatment": value.capping_treatment,
  //               "utilization": value.utilization,
  //               "applications": value.applications,
  //               "pore_size_label": value.pore_size_label,
  //               "particle_size_label": value.particle_size_label,
  //               "length_label": value.length_label,
  //               "inner_diameter_label": value.inner_diameter_label,
  //               "bonding": value.bonding,
  //               "pore_size": value.pore_size,
  //               "particle_size": value.particle_size,
  //               "length": value.length,
  //               "inner_diameter": value.inner_diameter,
  //               "bonding_label": value.bonding_label
  //             }
  //           )
  //         }
  //         )
  //       )

  //       dispatch(isVisibilityProgressBar(false));
  //       dispatch(ShowAlert({ text: "Fetch data ok.", typeAlert: enumTypeOfAlert.success, lastTimeecondsUpdate: (new Date()).getSeconds() }));
  //     })
  //     .catch((e: Error) => {
  //       console.log("response:(");

  //       console.log(e);
  //       dispatch(isVisibilityProgressBar(false));
  //       if (isAnySimilarInterfaceResponseErrorFromServer(e)) {
  //         dispatch(ShowAlert({ text: `${(e as interfaceResponseErrorFromServer).data.errors.list[0].attr} : ${(e as interfaceResponseErrorFromServer).data.errors.list[0].detail}`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
  //       }
  //       else {
  //         dispatch(ShowAlert({ text: `لطفا به ما خبر دهید`, typeAlert: enumTypeOfAlert.error, lastTimeecondsUpdate: (new Date()).getSeconds() }));
  //       }
  //     });
  // }
  useEffect(() => {
    // requestForGetProducts().then(requestForGetFilters)

  }, []);

  return (
    <div style={{
      width: "100%",
      height: "auto",
      overflow: "auto",
      display: "flex",
      // paddingTop: "90px",
      flexDirection: "column",
      justifyContent: "start",
      alignContent: "start",
      alignItems: "start",
      textAlign: "center",
      background: "#FFF",
      boxSizing: "border-box",
    }}>
      <Header>
        <ImageOfBackgroundHeader />
        <h1>{confComps.screens.product.accessories.header.title}</h1>

      </Header>
      <Main>


        <div style={{
          width: "100%", maxWidth: "1200px",
          overflow: "hidden", boxSizing: "border-box"
        }}>
          <GridFromMUI container
            justifyContent="center" alignItems="stretch"
            rowSpacing={{ xs: 0, sm: 2, md: 2, lg: 3, xl: 3 }}
            // columnSpacing={{ xs: 0, sm: 1, md: 2, lg: 3, xl: 4 }}
            columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 4 }}>
            {getProductsList.map((product, index, array) => {
              return (
                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                  <div style={{
                    width: "90%", display: "flex",
                    direction: "ltr",
                    height: "100%",
                    boxSizing: "border-box",
                    flexDirection: "column", alignItems: "center", justifyContent: "space-between"
                  }}>
                    <ImageSquare img={`${product.info.img}`} width={"100%"}
                      borderRadius="8px" border="1px solid white"
                      isScaleable={{
                        image: product.info.img,
                        description: product.info.description,
                        title: product.info.title
                      }}
                    />
                    <h6>{product.info.title}</h6>
                    <p>{product.info.description}</p>
                    <div style={{ width: "100%", boxSizing: "border-box" }}>
                      <ButtonAddBasket isSelected={product.isSelected}
                        style={{
                          direction: mTheme.direction,
                          boxSizing: "border-box",
                        }}
                        endIcon={product.isSelected ?
                          <RemoveRounded /> : <AddRounded />

                        }
                        onClick={() => {
                          handleOnClickBasketOfProduct(product.info.id)
                        }}
                      >
                        <ShadowButtonProducts />
                        {product.isSelected ?
                          confComps.screens.product.buttons.removeFromBasket : confComps.screens.product.buttons.addToBasket}
                      </ButtonAddBasket>
                    </div>

                  </div>
                </Grid>
              )
            })};

          </GridFromMUI>
          <div style={{
            maskBorderMode: "inherit", flexDirection: "column",
            justifyContent: "space-between",
            // alignItems:"center",
            display: getProductsList.filter(product => product.isSelected == true).length == 0 ? "none" : "flex"
          }}>

            <DivCardSubmit>
              <h3 style={{ alignSelf: "center" }}>{confComps.screens.product.massSpectrometry.main.tableProductSelected.title}</h3>
              <p style={{ alignSelf: "center", color: colors.screens.border, textAlign: "center" }}>
                {`${getProductsList.filter(product => product.isSelected == true).length} ${confComps.screens.product.massSpectrometry.main.tableProductSelected.subtitle}`}
              </p>
              <div style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                gap: "calc(1vh + 8px)",
              }}>

                {
                  getProductsList.filter(product => product.isSelected == true).map((value, index, array) => {
                    return (
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                        marginBlock: "calc(1vh + 1rem)"
                      }}>
                        <ImageSquare iconSvg={RemoveRounded} width="3rem"
                          background={colors.theme.pink.dark40}
                          color="white"
                          border="1px solid white"
                          borderRadius="90%"
                          callbackOnclick={() => {
                            handleOnClickBasketOfProduct(value.info.id)
                          }} />
                        <div>
                          {confComps.screens.product.selectedProduct.header}
                          {value.info.title}
                        </div>
                      </div>
                    )
                  })
                }

              </div>

              <h6 style={{
                color: colors.screens.border,
                marginBottom: "12px"
              }}>{confComps.screens.product.massSpectrometry.main.tableProductSelected.form.title}
              </h6>
              <p style={{ textAlign: "center", margin: "13px 96px 34px" }}>
                {confComps.screens.product.massSpectrometry.main.tableProductSelected.form.subtitle}
              </p>
              <GridFromMUI container
                alignItems="start"
                rowSpacing={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }}
                columnSpacing={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}
                columns={{ xs: 2, sm: 2, md: 4, lg: 4, xl: 4 }}
              >
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <TextFieldFromMUI
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={confComps.screens.product.massSpectrometry.main.tableProductSelected.form.items.name.hint}
                    onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setFormName(event.target.value) }}
                    multiline={false}
                    isHintTextCenter={false} isValueMustLtr={false} isSmallHintShow={false} >
                  </TextFieldFromMUI>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <TextFieldFromMUI
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={confComps.screens.product.massSpectrometry.main.tableProductSelected.form.items.email.hint}
                    onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setFormEmail(event.target.value) }}
                    multiline={false}
                    isHintTextCenter={false} isValueMustLtr={false} isSmallHintShow={false} >
                  </TextFieldFromMUI>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <TextFieldFromMUI
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={confComps.screens.product.massSpectrometry.main.tableProductSelected.form.items.company.hint}
                    onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setFormCompany(event.target.value) }}
                    multiline={false}
                    isHintTextCenter={false} isValueMustLtr={false} isSmallHintShow={false} >
                  </TextFieldFromMUI>
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <TextFieldFromMUI
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={confComps.screens.product.massSpectrometry.main.tableProductSelected.form.items.country.hint}
                    onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setFormCountry(event.target.value) }}
                    multiline={false}
                    isHintTextCenter={false} isValueMustLtr={false} isSmallHintShow={false} >
                  </TextFieldFromMUI>
                </Grid>
                <Grid item xs={2} sm={2} md={4} lg={4} xl={4}>
                  <TextFieldFromMUI
                    variant="outlined"
                    style={{ width: "100%" }}
                    label={confComps.screens.product.massSpectrometry.main.tableProductSelected.form.items.message.hint}
                    onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { setFormMessage(event.target.value) }}
                    multiline={true}
                    isHintTextCenter={false} isValueMustLtr={false} isSmallHintShow={false} >
                  </TextFieldFromMUI>
                </Grid>
              </GridFromMUI>
              <div style={{ marginTop: "15px" }}>
                <ButtonFormSend onClick={handleOnClickSubmitForm}>
                  {confComps.screens.product.massSpectrometry.main.tableProductSelected.form.items.button.text}
                </ButtonFormSend>
              </div>
            </DivCardSubmit>
          </div>
        </div >
        <div style={{
          width: "100%", position: "relative", padding: "80px 0",
          display: "flex", flexDirection: "column", alignItems: "center",
          justifyContent: "center",
          background: colors.screens.product.main.footer.background,
        }}>
          <h2 style={{ color: colors.screens.color, marginBottom: "20px" }}>
            {confComps.screens.product.footer.title}
          </h2>
          <ButtonProducts>
            <ShadowButtonProducts />
            {confComps.screens.product.footer.buttonService.text}
          </ButtonProducts>
          <ButtonDownloadBrochure>
            <ShadowButtonProducts />
            {confComps.screens.product.footer.buttonBrochure.text}
          </ButtonDownloadBrochure>

          <ImageOfBackgroundFooter />
        </div>
      </Main >
    </div >
  );
};

export default SelectAccessories;

