import { useEffect, useState } from "react";
import { PhoneAndroidRounded, EmailRounded, MapRounded, Phone, HomeRounded, PhoneRounded, LinkRounded, WhatsApp } from "@mui/icons-material";
import colors from "../../config/colors";
import { interfaceFooter } from "./interfaceFooter";
import { Link } from "react-router-dom";
import { styled as styledMUI } from "@mui/material/styles";
import { SectionFlexWrap } from "../sectionFullWidth/SectionFlexWrap";
import { ConfigComps } from "../../config/ConfigCOMP";
import { GridFromMUI } from "../MUIConfig/GridFromMUI";
import { Grid, useTheme } from "@mui/material";
import links from "../../config/links";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { SetDirectionOfTheme } from "../../store/themeModeSlice";

const DivBoxColumn = styledMUI(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    borderInlineEnd: "1px dashed rgba(0,80,159,0.7)",
    paddingInline: "24px",
}));

const SectionTitle = styledMUI("a")(({ theme }) => ({
    color: "#00509f",
    fontSize: "1.3rem",
    fontWeight: "600",
    marginBottom: "calc(2vh + 12px)"
}));
const OurApproach = styledMUI("p")(({ theme }) => ({
    lineHeight: "22px",
    fontSize: "12px",
    fontStyle: "italic",
    textAlign: "justify",
    marginBottom: "2rem",
}));
const AboutUsDesc = styledMUI("p")(({ theme }) => ({
    fontSize: "15px",
    fontWeight: "500",
    lineHeight: "22px",
    textAlign: "justify",
    marginBottom: "2rem",
}));
const AboutUsButton = styledMUI("a")(({ theme }) => ({
    color: "#54c0ee",
    cursor: "pointer",
}));
const FastAccessList = styledMUI("ul")(({ theme }) => ({
    listStyle: "none",
    paddingInlineStart: "8px",
}));
const FastAccessItem = styledMUI("li", { shouldForwardProp: (prop) => prop !== 'selected', })<{ selected: boolean; }>(({
    theme,
    selected
}) => ({
    marginBottom: "0rem",
    marginRight: "0rem",
    // color: selected ? colors.footer.textSelected : colors.footer.textUnselected,
    position: "relative",
}));
const FastAccessItemLink = styledMUI(Link)(({ theme }) => ({
    theme,

}) => ({
    textDecoration: "none",
    marginBottom: "0.5rem",
    color: "black",
    lineHeight: "22px",
    fontSize: "12px",
    marginRight: "1rem",
    position: "relative",
}));
const ContactList = styledMUI("ul")(({ theme }) => ({
    listStyle: "none",
    display: "block",
    height: "auto",
}));

const ContactItem = styledMUI("li")(({ theme }) => ({
    marginBottom: "4px",
    display: "flex",
}));
const Triangle = styledMUI("div")(({ theme }) => ({
    width: 0,
    height: 0,
    border: "0.3rem solid transparent",

    position: "absolute",
    right: theme.direction == "rtl" ? "-0.5rem" : undefined,
    left: theme.direction == "ltr" ? "-0.5rem" : undefined,
    top: "50%",
    transform: "translate(-30%, -50%)",
    borderRight: theme.direction == "ltr" ? 0 : "0.5rem solid #54c0ee",
    borderLeft: theme.direction == "rtl" ? 0 : "0.5rem solid #54c0ee",
}));
const LogoContainer = styledMUI("div")(({ theme }) => ({
    fontsize: "32px",
    color: colors.green.dark,
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    marginInlineEnd: "calc(4px + 1vw)",
}));

const DescContainer = styledMUI("section")(({ theme }) => ({
    marginLeft: "4px",
    marginRight: "4px",
}));

const ContactTitleOne = styledMUI("p")(({ theme }) => ({
    color: "#54c0ee",
    marginBottom: 0,
    marginTop: "1px",
}));


const ContactTitleTwo = styledMUI("p")(({ theme }) => ({
    marginTop: "1px",
    lineHeight: "22px",
    fontSize: "15px",
    fontWeight: "500",
    marginBottom: 0,
    textAlign: "start",
}));
const ItemListContainer = styledMUI("ul")(({ theme }) => ({
    listStyle: "none",
    marginBlock: "0px !important",
    gap: "calc(8px + 0.5vh)",
    width: "",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    flexDirection: "column",
}));
const ItemContainer = styledMUI('p', { shouldForwardProp: (prop) => prop !== "color" && prop !== 'selected', })<{ selected?: boolean; color?: string }>(({
    theme,
    selected
}) => ({
    [theme.breakpoints.up('md')]: {
        // fontFamily: `${selected ? 'vazirmatn-ExtraBold' : 'vazirmatn-medium'} !important`,
        // fontSize: `${selected ? '1.56rem' : '1.37rem'} !important`,
    },
    [theme.breakpoints.down('md')]: {
        // fontFamily: `${selected ? 'vazirmatn-ExtraBold' : 'vazirmatn-Regular'} !important`,
        // fontSize: `${selected ? '1.37rem' : '1rem'} !important`,
    },
    margin: "0",
    fontSize: "14px",
    fontWeight: "600",
    textDecoration: "blink",
    border: "none",
    cursor: "pointer",
    color: colors.Navbar.color
}));
const Footer = (propsFooter: interfaceFooter) => {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const isLocationContainLink = (url: string) => {
        if (window.location.href.toLowerCase().includes(url.toLowerCase())) {
            return true;
        }
        return false;
    };
    const handleChangeLanguge = async (select: "rtl" | "ltr") => {
        i18n.changeLanguage(select == "rtl" ? "fa" : "en");
        dispatch(SetDirectionOfTheme(select));
        window.scrollTo({
            top: 0,
            // behavior: "instant", // for a smooth scrolling
        });
        // window.location.reload();
    };
    const checkLanguege = async () => {
        const queryString = window.location.search;
        // if (!queryString) {
        //     return {};
        // }
        const urlParams = new URLSearchParams(queryString);
        console.log("langggggggg", urlParams)
        if (urlParams.get("lang") == "fa") {
            i18n.changeLanguage("fa");
            dispatch(SetDirectionOfTheme("rtl"));
            console.log("langggggggg", ":FA")
        }
        else if (urlParams.get("lang") == "en") {
            console.log()
            i18n.changeLanguage("en");
            dispatch(SetDirectionOfTheme("ltr"));
            console.log("langggggggg", ":EN")
        } else {
            i18n.changeLanguage(mTheme.direction == "rtl" ? "fa" : "en");
        }

    }
    useEffect(() => {
        checkLanguege();
    }, []);
    return (
        <div style={{ paddingInline: "calc(1vw + 1rem)", boxSizing: "border-box" }}>
            <GridFromMUI style={{ background: "#fff", marginTop: "1rem", }} container
                justifyContent="space-between" alignItems="stretch"
                rowSpacing={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}
                columnSpacing={{ xs: 2, sm: 2, md: 4, lg: 4, xl: 4 }}
                columns={{ xs: 1, sm: 1, md: 3, lg: 3, xl: 3 }}>
                <DivBoxColumn item xs={1} sm={1} md={1} lg={1} xl={1}>
                    <SectionTitle>
                        {confComps.footerMainPage.aboutUs.title}
                    </SectionTitle>
                    <AboutUsDesc>
                        {confComps.footerMainPage.aboutUs.discription}
                    </AboutUsDesc>
                </DivBoxColumn>
                <DivBoxColumn item xs={1} sm={1} md={1} lg={1} xl={1}>
                    <SectionTitle>
                        {confComps.footerMainPage.contactUs.title}
                    </SectionTitle>

                    <ContactItem>
                        <LogoContainer>
                            <HomeRounded />
                        </LogoContainer>
                        <DescContainer>

                            <ContactTitleTwo>
                                {confComps.footerMainPage.contactUs.address}
                            </ContactTitleTwo>
                        </DescContainer>
                    </ContactItem>
                    <div style={{ borderTop: "1px dashed #9e9e9e", marginBlock: "8px", width: "50%", alignSelf: "center" }}></div>
                    <ContactItem>
                        <Link to={"https://wa.me/+989120711156"} style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "start",
                            justifyContent: "start",
                            color: "black",
                            textDecoration: "none"
                        }}>
                            <LogoContainer>
                                <WhatsApp />
                            </LogoContainer>
                            <DescContainer>

                                <ContactTitleTwo>
                                    {confComps.footerMainPage.contactUs.whatsApp}
                                </ContactTitleTwo>
                            </DescContainer>
                        </Link>
                    </ContactItem>
                    <div style={{ borderTop: "1px dashed #9e9e9e", marginBlock: "8px", width: "50%", alignSelf: "center" }}></div>
                    <ContactItem>
                        <Link to={"mailto:info@kheradazma.org"} style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "start",
                            justifyContent: "start",
                            color: "black",
                            textDecoration: "none"
                        }}>
                            <LogoContainer>
                                <EmailRounded />
                            </LogoContainer>
                            <DescContainer>

                                <ContactTitleTwo>
                                    {confComps.footerMainPage.contactUs.email}
                                </ContactTitleTwo>
                            </DescContainer>
                        </Link>
                    </ContactItem>
                    <div style={{ borderTop: "1px dashed #9e9e9e", marginBlock: "8px", width: "50%", alignSelf: "center" }}></div>
                    {/* <ContactItem>
                    <LogoContainer>
                        <LinkRounded />
                    </LogoContainer>
                    <DescContainer>

                        <ContactTitleTwo>
                            <FastAccessItemLink to={`${links.companyPage}${mTheme.direction == "rtl" ? "fa" : "en"}`}>
                                {confComps.footerMainPage.contactUs.site.text}
                            </FastAccessItemLink>
                        </ContactTitleTwo>
                    </DescContainer>
                </ContactItem> */}
                    <ContactItem>
                        <LogoContainer>
                            <PhoneRounded />
                        </LogoContainer>
                        <DescContainer>

                            <ContactTitleTwo>
                                {confComps.footerMainPage.contactUs.phone}
                            </ContactTitleTwo>
                        </DescContainer>
                    </ContactItem>

                </DivBoxColumn>
                <DivBoxColumn item xs={1} sm={1} md={1} lg={1} xl={1}>
                    <SectionTitle>
                        {confComps.footerMainPage.information.title}
                    </SectionTitle>


                    <ItemListContainer>
                        {confComps.navbarMainPage.linksTitles.map(((value, index) => {
                            return (
                                <li style={{ display: "inline-block" }}>
                                    <Link to={`${value.link}${mTheme.direction == "ltr" ? "en" : "fa"}`} key={index} style={{ textDecoration: "blink", textAlign: "center" }}>
                                        <ItemContainer selected={isLocationContainLink(value.link)}>
                                            {value.name}
                                        </ItemContainer>
                                    </Link>
                                </li>
                            );
                        }))}
                    </ItemListContainer>
                </DivBoxColumn>


            </GridFromMUI>
            <div style={{ clear: "both", textAlign: "center", width: "100%" }}>
                <ul style={{ margin: "1rem" }}>
                    <li style={{ display: "inline-block", whiteSpace: "nowrap", margin: "0 0.6rem", cursor: "pointer" }}
                        onClick={() => { handleChangeLanguge("rtl") }}>
                        <img src="https://kheradazma.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/fa.png"
                            style={{ cursor: "pointer" }} />
                        <span
                            style={{ fontSize: "0.9rem", cursor: "pointer" }}
                        >فارسی</span>
                    </li>
                    <li style={{ display: "inline-block", whiteSpace: "nowrap", margin: "0 0.6rem", cursor: "pointer" }}
                        onClick={() => { handleChangeLanguge("ltr") }}>
                        <img
                            style={{ cursor: "pointer" }}
                            src="https://kheradazma.org/wp-content/plugins/sitepress-multilingual-cms/res/flags/en.png" />
                        <span
                            style={{
                                fontSize: "0.9rem",
                                cursor: "pointer"
                            }}>
                            English
                        </span>

                    </li>
                </ul>
            </div>
        </div>
    )
}
export default Footer;