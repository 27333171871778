import links from "./links";
import {
    AutoGraphRounded,
    BarChartOutlined, CallRounded, Category, ConnectWithoutContactRounded, DateRangeOutlined, DesignServicesRounded,
    DiamondRounded,
    DiamondSharp,
    Diversity3Outlined, Diversity3Rounded, EmojiEventsOutlined,
    ExtensionRounded,
    FlagRounded,
    FlagSharp,
    FolderCopyOutlined,
    FolderSharp, GroupRounded,
    GroupSharp, HomeRounded,
    RollerShadesRounded,
    RollerShadesSharp,
    SchoolRounded,
    SettingsSharp,
    SettingsVoiceOutlined,
    SpeedOutlined,
    SupervisorAccountRounded,
    SvgIconComponent,
    TextIncreaseRounded,
    TimerOutlined,
    TimerSharp,
    TuneOutlined, Warning, WebRounded
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { EnumTrainingTypeFile } from "../helper/Training/EnumTraining";

export const ConfigComps = () => {
    const { t, i18n } = useTranslation();

    return (
        {
            navbarMainPage: {
                logo: t('navbar.logo'),
                linksTitles: [
                    {
                        name: t('navbar.linksTitles.1'),
                        link: links.wordpressLink.mainPage,
                        icon: HomeRounded,
                    },
                    {
                        name: t('navbar.linksTitles.2'),
                        link: links.training.main,
                        icon: SchoolRounded,
                    },
                    {
                        name: t('navbar.linksTitles.3'),
                        link: links.wordpressLink.aboutUs,
                        icon: Diversity3Rounded,
                    },
                    {
                        name: t('navbar.linksTitles.4'),
                        link: links.wordpressLink.contactUs,
                        icon: ConnectWithoutContactRounded,
                    },
                ],
                product: t('navbar.product'),
                buttonProduct: {
                    name: t('navbar.buttonProduct'),
                }
            },
            pageNotFound: t('pageNotFound'),
            screens: {
                training: {
                    main: {
                        header: t('screens.training.main.header'),
                        subHeader: {
                            linked: [
                                t('screens.training.main.subHeader.linked.0'),
                                t('screens.training.main.subHeader.linked.1'),
                            ],
                        },
                        category: [
                            {
                                title: t('screens.training.main.category.0.title'),
                            },
                            {
                                title: t('screens.training.main.category.1.title'),
                            },
                            {
                                title: t('screens.training.main.category.2.title'),
                            },
                        ],
                        items: {
                            empty: t('screens.training.main.items.empty'),
                            buttonVideo: {
                                title: t('screens.training.main.items.buttonVideo.title'),
                            },
                            buttonDoc: {
                                title: t('screens.training.main.items.buttonDoc.title'),
                            },
                            array: [
                                {
                                    name: t('screens.training.main.items.array.0.name'),
                                    link: "https://kheradazma.org/wp-content/uploads/2022/09/1-Final-Theory-Autosaved.pptx",
                                    type: EnumTrainingTypeFile.file,
                                    ownerCategory: [
                                        t('screens.training.main.category.0.title'),

                                    ]
                                },
                                {
                                    name: t('screens.training.main.items.array.1.name'),
                                    link: "https://kheradazma.org/wp-content/uploads/2022/09/2.-FPLC-Prep-1.pptx",
                                    type: EnumTrainingTypeFile.file,
                                    ownerCategory: [
                                        t('screens.training.main.category.1.title'),

                                    ]
                                },
                                {
                                    name: t('screens.training.main.items.array.2.name'),
                                    link: "https://kheradazma.org/wp-content/uploads/2022/09/3-Principal-Scale-Up.pptx",
                                    type: EnumTrainingTypeFile.file,
                                    ownerCategory: [
                                        t('screens.training.main.category.0.title'),

                                    ]
                                },
                                {
                                    name: t('screens.training.main.items.array.3.name'),
                                    link: "https://kheradazma.org/wp-content/uploads/2022/09/2020-preparative-chromatography.pdf",
                                    type: EnumTrainingTypeFile.file,
                                    ownerCategory: [
                                        t('screens.training.main.category.0.title'),

                                    ]
                                },

                                {
                                    name: t('screens.training.main.items.array.4.name'),
                                    link: "https://kheradazma.org/wp-content/uploads/2022/12/IMG_0883.mp4",
                                    type: EnumTrainingTypeFile.video,
                                    ownerCategory: [
                                        t('screens.training.main.category.0.title'),

                                    ]
                                },
                                {
                                    name: t('screens.training.main.items.array.5.name'),
                                    link: "https://kheradazma.org/wp-content/uploads/2022/12/IMG_0885.mp4",
                                    type: EnumTrainingTypeFile.video,
                                    ownerCategory: [
                                        t('screens.training.main.category.0.title'),

                                    ]
                                },
                                {
                                    name: t('screens.training.main.items.array.6.name'),
                                    link: "https://kheradazma.org/wp-content/uploads/2022/12/IMG_0882.mp4",
                                    type: EnumTrainingTypeFile.video,
                                    ownerCategory: [
                                        t('screens.training.main.category.0.title'),

                                    ]
                                },
                                {
                                    name: t('screens.training.main.items.array.7.name'),
                                    link: "https://kheradazma.org/wp-content/uploads/2022/12/IMG_0884.mp4",
                                    type: EnumTrainingTypeFile.video,
                                    ownerCategory: [
                                        t('screens.training.main.category.0.title'),

                                    ]
                                },
                            ]
                        },

                    },
                },
                product: {
                    buttons: {
                        addToBasket: t('screens.product.buttons.addToBasket'),
                        removeFromBasket: t('screens.product.buttons.removeFromBasket')
                    },
                    selectedProduct: {
                        header: t('screens.product.selectedProduct.header'),
                    },
                    HPLC: {
                        header: {
                            backgroundImage: "/assets/img/background_header_product.png",
                            title: t('screens.product.HPLC.header.title'),
                            subtitle1: t('screens.product.HPLC.header.subtitle1'),
                            subtitle2: t('screens.product.HPLC.header.subtitle2'),
                        },
                        main: {
                            cardSelectSize: {
                                section1: {
                                    title: t('screens.product.HPLC.main.cardSelectSize.section1.title'),
                                },
                                section2: {
                                    title: t('screens.product.HPLC.main.cardSelectSize.section2.title'),
                                },
                                section3: {
                                    title: t('screens.product.HPLC.main.cardSelectSize.section3.title'),

                                },
                                section4: {
                                    title: t('screens.product.HPLC.main.cardSelectSize.section4.title'),
                                },
                                tabs: {
                                    tab1: {
                                        title: t('screens.product.HPLC.main.cardSelectSize.tabs.tab1.title'),
                                    },
                                    tab2: {
                                        title: t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.title'),
                                        bondedSlices: [
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.0'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.1'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.2'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.3'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.4'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.5'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.6'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.7'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.8'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.9'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.10'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.11'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.12'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.13'),
                                            t('screens.product.HPLC.main.cardSelectSize.tabs.tab2.bondedSlices.14'),


                                        ]
                                    },

                                },
                                productAvailabe: t('screens.product.HPLC.main.cardSelectSize.productAvailabe'),
                            },
                            table: {
                                header: [
                                    {
                                        "name": "",
                                        "width": "42px",
                                        "isBorderActive": false
                                    },
                                    {
                                        "name": "",
                                        "width": "42px",
                                        "isBorderActive": false
                                    },
                                    {
                                        "name": t("screens.product.HPLC.main.table.header.2.name"),
                                        "width": undefined,
                                        "isBorderActive": true
                                    },
                                    {
                                        "name": t("screens.product.HPLC.main.table.header.3.name"),
                                        "width": undefined,
                                        "isBorderActive": true
                                    },
                                    {
                                        "name": t("screens.product.HPLC.main.table.header.4.name"),
                                        "width": undefined,
                                        "isBorderActive": true
                                    },
                                    {
                                        "name": t("screens.product.HPLC.main.table.header.5.name"),
                                        "width": undefined,
                                        "isBorderActive": true
                                    },
                                    {
                                        "name": t("screens.product.HPLC.main.table.header.6.name"),
                                        "width": undefined,
                                        "isBorderActive": true
                                    },
                                    {
                                        "name": t("screens.product.HPLC.main.table.header.7.name"),
                                        "width": undefined,
                                        "isBorderActive": true
                                    },

                                ],
                                bodyTemp: [
                                    {
                                        "index": 1,
                                        "isSelected": false,
                                        "productDescription": "SPS200-2",
                                        "particleSize": "2",
                                        "poreSize": "200",
                                        "bonding": "-",
                                        "functionGroup": "-",
                                        "grade": "SPS",
                                        "special": "",
                                    },
                                    {
                                        "index": 2,
                                        "isSelected": false,
                                        "productDescription": "SPS200-2P",
                                        "particleSize": "2P",
                                        "poreSize": "200",
                                        "bonding": "-",
                                        "functionGroup": "-",
                                        "grade": "SPS",
                                        "special": "",
                                    },
                                    {
                                        "index": 3,
                                        "isSelected": false,
                                        "productDescription": "SPS200-3",
                                        "particleSize": "3",
                                        "poreSize": "200",
                                        "bonding": "-",
                                        "functionGroup": "-",
                                        "grade": "SPS",
                                        "special": "",
                                    },
                                    {
                                        "index": 4,
                                        "isSelected": false,
                                        "productDescription": "SPS200-3P",
                                        "particleSize": "3P",
                                        "poreSize": "200",
                                        "bonding": "-",
                                        "functionGroup": "-",
                                        "grade": "SPS",
                                        "special": "",
                                    },
                                    {
                                        "index": 5,
                                        "isSelected": true,
                                        "productDescription": "SPS200-4",
                                        "particleSize": "4",
                                        "poreSize": "200",
                                        "bonding": "-",
                                        "functionGroup": "-",
                                        "grade": "SPS",
                                        "special": "",
                                    }
                                ]
                            },
                            tableProductSelected: {
                                title: t('screens.product.HPLC.main.tableProductSelected.title'),
                                subtitle: t('screens.product.HPLC.main.tableProductSelected.subtitle'),
                                form: {
                                    title: t('screens.product.HPLC.main.tableProductSelected.form.title'),
                                    subtitle: t('screens.product.HPLC.main.tableProductSelected.form.subtitle'),
                                    items: {
                                        name: {
                                            hint: t('screens.product.HPLC.main.tableProductSelected.form.items.name.hint'),
                                        },
                                        email: {
                                            hint: t('screens.product.HPLC.main.tableProductSelected.form.items.email.hint'),
                                        },
                                        company: {
                                            hint: t('screens.product.HPLC.main.tableProductSelected.form.items.company.hint'),
                                        },
                                        country: {
                                            hint: t('screens.product.HPLC.main.tableProductSelected.form.items.country.hint'),
                                        },
                                        message: {
                                            hint: t('screens.product.HPLC.main.tableProductSelected.form.items.message.hint'),
                                        },
                                        button: {
                                            text: t('screens.product.HPLC.main.tableProductSelected.form.items.button.text'),
                                        }

                                    }
                                }
                            },

                        }
                    },
                    FPLC: {
                        header: {
                            backgroundImage: "/assets/img/background_header_product.png",
                            title: t('screens.product.FPLC.header.title'),
                            subtitle1: t('screens.product.FPLC.header.subtitle1'),
                            subtitle2: t('screens.product.FPLC.header.subtitle2'),
                        },
                        main: {
                            cardSelectSize: {
                                section1: {
                                    title: t('screens.product.FPLC.main.cardSelectSize.section1.title'),
                                },
                                section2: {
                                    title: t('screens.product.FPLC.main.cardSelectSize.section2.title'),
                                },
                                section3: {
                                    title: t('screens.product.FPLC.main.cardSelectSize.section3.title'),

                                },
                                section4: {
                                    title: t('screens.product.FPLC.main.cardSelectSize.section4.title'),
                                },
                                tabs: {
                                    tab1: {
                                        title: t('screens.product.FPLC.main.cardSelectSize.tabs.tab1.title'),
                                    },
                                    tab2: {
                                        title: t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.title'),
                                        bondedSlices: [
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.0'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.1'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.2'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.3'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.4'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.5'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.6'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.7'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.8'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.9'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.10'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.11'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.12'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.13'),
                                            t('screens.product.FPLC.main.cardSelectSize.tabs.tab2.bondedSlices.14'),


                                        ]
                                    },

                                },
                                productAvailabe: t('screens.product.FPLC.main.cardSelectSize.productAvailabe'),
                            },
                            table: {
                                header: [
                                    {
                                        "name": "",
                                        "width": "42px",
                                        "isBorderActive": false
                                    },
                                    {
                                        "name": "",
                                        "width": "42px",
                                        "isBorderActive": false
                                    },
                                    {
                                        "name": "Product Description",
                                        "width": undefined,
                                        "isBorderActive": true
                                    },
                                    {
                                        "name": "Particle Size (µm)",
                                        "width": undefined,
                                        "isBorderActive": true
                                    },
                                    {
                                        "name": "Pore Size (Å)",
                                        "width": undefined,
                                        "isBorderActive": true
                                    },
                                    {
                                        "name": "Bonding",
                                        "width": undefined,
                                        "isBorderActive": true
                                    },
                                    {
                                        "name": "Length (mm)",
                                        "width": undefined,
                                        "isBorderActive": true
                                    },
                                    {
                                        "name": "Inner Diameter (mm)",
                                        "width": undefined,
                                        "isBorderActive": true
                                    },

                                ],
                                bodyTemp: [
                                    {
                                        "index": 1,
                                        "isSelected": false,
                                        "productDescription": "SPS200-2",
                                        "particleSize": "2",
                                        "poreSize": "200",
                                        "bonding": "-",
                                        "functionGroup": "-",
                                        "grade": "SPS",
                                        "special": "",
                                    },
                                    {
                                        "index": 2,
                                        "isSelected": false,
                                        "productDescription": "SPS200-2P",
                                        "particleSize": "2P",
                                        "poreSize": "200",
                                        "bonding": "-",
                                        "functionGroup": "-",
                                        "grade": "SPS",
                                        "special": "",
                                    },
                                    {
                                        "index": 3,
                                        "isSelected": false,
                                        "productDescription": "SPS200-3",
                                        "particleSize": "3",
                                        "poreSize": "200",
                                        "bonding": "-",
                                        "functionGroup": "-",
                                        "grade": "SPS",
                                        "special": "",
                                    },
                                    {
                                        "index": 4,
                                        "isSelected": false,
                                        "productDescription": "SPS200-3P",
                                        "particleSize": "3P",
                                        "poreSize": "200",
                                        "bonding": "-",
                                        "functionGroup": "-",
                                        "grade": "SPS",
                                        "special": "",
                                    },
                                    {
                                        "index": 5,
                                        "isSelected": true,
                                        "productDescription": "SPS200-4",
                                        "particleSize": "4",
                                        "poreSize": "200",
                                        "bonding": "-",
                                        "functionGroup": "-",
                                        "grade": "SPS",
                                        "special": "",
                                    }
                                ]
                            },
                            tableProductSelected: {
                                title: t('screens.product.FPLC.main.tableProductSelected.title'),
                                subtitle: t('screens.product.FPLC.main.tableProductSelected.subtitle'),
                                form: {
                                    title: t('screens.product.FPLC.main.tableProductSelected.form.title'),
                                    subtitle: t('screens.product.FPLC.main.tableProductSelected.form.subtitle'),
                                    items: {
                                        name: {
                                            hint: t('screens.product.FPLC.main.tableProductSelected.form.items.name.hint'),
                                        },
                                        email: {
                                            hint: t('screens.product.FPLC.main.tableProductSelected.form.items.email.hint'),
                                        },
                                        company: {
                                            hint: t('screens.product.FPLC.main.tableProductSelected.form.items.company.hint'),
                                        },
                                        country: {
                                            hint: t('screens.product.FPLC.main.tableProductSelected.form.items.country.hint'),
                                        },
                                        message: {
                                            hint: t('screens.product.FPLC.main.tableProductSelected.form.items.message.hint'),
                                        },
                                        button: {
                                            text: t('screens.product.FPLC.main.tableProductSelected.form.items.button.text'),
                                        }

                                    }
                                }
                            }
                        }
                    },
                    massSpectrometry: {
                        header: {
                            backgroundImage: "/assets/img/background_header_product.png",
                            title: t('screens.product.massSpectrometry.header.title'),
                            subtitle1: t('screens.product.massSpectrometry.header.subtitle1'),
                            subtitle2: t('screens.product.massSpectrometry.header.subtitle2'),
                        },
                        main: {
                            cardSelectSize: {
                                section1: {
                                    title: t('screens.product.massSpectrometry.main.cardSelectSize.section1.title'),
                                },
                                section2: {
                                    title: t('screens.product.massSpectrometry.main.cardSelectSize.section2.title'),
                                },
                                section3: {
                                    title: t('screens.product.massSpectrometry.main.cardSelectSize.section3.title'),

                                },
                                section4: {
                                    title: t('screens.product.massSpectrometry.main.cardSelectSize.section4.title'),
                                },
                                tabs: {
                                    tab1: {
                                        title: t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab1.title'),
                                    },
                                    tab2: {
                                        title: t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.title'),
                                        bondedSlices: [
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.0'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.1'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.2'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.3'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.4'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.5'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.6'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.7'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.8'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.9'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.10'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.11'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.12'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.13'),
                                            t('screens.product.massSpectrometry.main.cardSelectSize.tabs.tab2.bondedSlices.14'),


                                        ]
                                    },

                                },
                                productAvailabe: t('screens.product.massSpectrometry.main.cardSelectSize.productAvailabe'),
                            },
                            product: {
                                bodyTemp: [
                                    {
                                        "isSelected": false,
                                        info: {
                                            "id": 1,
                                            "description": "Mass Spectrometry",
                                            "img": "https://kheradazma.org/wp-content/uploads/2022/08/product-desi-ms-min.png",
                                            "title": "DESI-MS",
                                        }
                                    },

                                ]
                            },
                            tableProductSelected: {
                                title: t('screens.product.massSpectrometry.main.tableProductSelected.title'),
                                subtitle: t('screens.product.massSpectrometry.main.tableProductSelected.subtitle'),
                                form: {
                                    title: t('screens.product.massSpectrometry.main.tableProductSelected.form.title'),
                                    subtitle: t('screens.product.massSpectrometry.main.tableProductSelected.form.subtitle'),
                                    items: {
                                        name: {
                                            hint: t('screens.product.massSpectrometry.main.tableProductSelected.form.items.name.hint'),
                                        },
                                        email: {
                                            hint: t('screens.product.massSpectrometry.main.tableProductSelected.form.items.email.hint'),
                                        },
                                        company: {
                                            hint: t('screens.product.massSpectrometry.main.tableProductSelected.form.items.company.hint'),
                                        },
                                        country: {
                                            hint: t('screens.product.massSpectrometry.main.tableProductSelected.form.items.country.hint'),
                                        },
                                        message: {
                                            hint: t('screens.product.massSpectrometry.main.tableProductSelected.form.items.message.hint'),
                                        },
                                        button: {
                                            text: t('screens.product.massSpectrometry.main.tableProductSelected.form.items.button.text'),
                                        }

                                    }
                                }
                            },

                        }
                    },
                    accessories: {
                        header: {
                            backgroundImage: "/assets/img/background_header_product.png",
                            title: t('screens.product.accessories.header.title'),
                            subtitle1: t('screens.product.accessories.header.subtitle1'),
                            subtitle2: t('screens.product.accessories.header.subtitle2'),
                        },
                        main: {
                            cardSelectSize: {
                                section1: {
                                    title: t('screens.product.accessories.main.cardSelectSize.section1.title'),
                                },
                                section2: {
                                    title: t('screens.product.accessories.main.cardSelectSize.section2.title'),
                                },
                                section3: {
                                    title: t('screens.product.accessories.main.cardSelectSize.section3.title'),

                                },
                                section4: {
                                    title: t('screens.product.accessories.main.cardSelectSize.section4.title'),
                                },
                                tabs: {
                                    tab1: {
                                        title: t('screens.product.accessories.main.cardSelectSize.tabs.tab1.title'),
                                    },
                                    tab2: {
                                        title: t('screens.product.accessories.main.cardSelectSize.tabs.tab2.title'),
                                        bondedSlices: [
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.0'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.1'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.2'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.3'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.4'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.5'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.6'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.7'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.8'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.9'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.10'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.11'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.12'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.13'),
                                            t('screens.product.accessories.main.cardSelectSize.tabs.tab2.bondedSlices.14'),


                                        ]
                                    },

                                },
                                productAvailabe: t('screens.product.accessories.main.cardSelectSize.productAvailabe'),
                            },
                            product: {
                                bodyTemp: [
                                    {
                                        "isSelected": false,
                                        info: {
                                            "id": 1,
                                            "title": "HPLC Pre-Column, C18",
                                            "description": "Analysis columns, HPLC",
                                            "img": "https://kheradazma.org/wp-content/uploads/2022/08/product-HPLC-precolumn-C18-min-1.png",
                                        }
                                    },
                                    {
                                        "isSelected": false,
                                        info: {
                                            "id": 2,
                                            "title": "One-Piece HPLC Column Connector",
                                            "description": "HPLC, One-Piece HPLC Column Connector",
                                            "img": "https://kheradazma.org/wp-content/uploads/2022/08/product-HPLC-one-piece-min-1.png",
                                        }
                                    },

                                ]
                            },
                            tableProductSelected: {
                                title: t('screens.product.accessories.main.tableProductSelected.title'),
                                subtitle: t('screens.product.accessories.main.tableProductSelected.subtitle'),
                                form: {
                                    title: t('screens.product.accessories.main.tableProductSelected.form.title'),
                                    subtitle: t('screens.product.accessories.main.tableProductSelected.form.subtitle'),
                                    items: {
                                        name: {
                                            hint: t('screens.product.accessories.main.tableProductSelected.form.items.name.hint'),
                                        },
                                        email: {
                                            hint: t('screens.product.accessories.main.tableProductSelected.form.items.email.hint'),
                                        },
                                        company: {
                                            hint: t('screens.product.accessories.main.tableProductSelected.form.items.company.hint'),
                                        },
                                        country: {
                                            hint: t('screens.product.accessories.main.tableProductSelected.form.items.country.hint'),
                                        },
                                        message: {
                                            hint: t('screens.product.accessories.main.tableProductSelected.form.items.message.hint'),
                                        },
                                        button: {
                                            text: t('screens.product.accessories.main.tableProductSelected.form.items.button.text'),
                                        }

                                    }
                                }
                            }

                        }
                    },
                    footer: {
                        backgroundImage: "/assets/img/background_product_footer.png",
                        title: t('screens.product.footer.title'),
                        buttonService: {
                            text: t('screens.product.footer.buttonService.text'),
                        },
                        buttonBrochure: {
                            text: t('screens.product.footer.buttonBrochure.text'),
                        }
                    },
                    sideBarProduct: {
                        headerGroup: {
                            title: t('screens.product.sideBarProduct.headerGroup.title'),
                            subtitle: t('screens.product.sideBarProduct.headerGroup.subtitle'),
                        },
                        physicalPropertiesForBareSilica: {
                            title: t('screens.product.sideBarProduct.physicalPropertiesForBareSilica.title'),
                            particuleSize: {
                                title: t('screens.product.sideBarProduct.physicalPropertiesForBareSilica.particuleSize.title'),
                                unit: "µm",

                            },
                            poreDiameter: {
                                title: t('screens.product.sideBarProduct.physicalPropertiesForBareSilica.poreDiameter.title'),
                                unit: "Å",
                            },
                            length: {
                                title: t('screens.product.sideBarProduct.physicalPropertiesForBareSilica.length.title'),
                                unit: "mm",
                            },
                            innerDiameter: {
                                title: t('screens.product.sideBarProduct.physicalPropertiesForBareSilica.innerDiameter.title'),
                                unit: "mm",
                            },
                            surfaceArea: {
                                title: t('screens.product.sideBarProduct.physicalPropertiesForBareSilica.surfaceArea.title'),
                            },

                        },
                        chemicalProperties: {
                            title: t('screens.product.sideBarProduct.chemicalProperties.title'),
                            siO2: {
                                title: "SiO",
                                unit: "%",
                            },
                            bonding: {
                                title: t('screens.product.sideBarProduct.chemicalProperties.bonding.title'),
                                unit: t('screens.product.sideBarProduct.chemicalProperties.bonding.unit'),
                            },
                            cappingTreatment: {
                                title: t('screens.product.sideBarProduct.chemicalProperties.cappingTreatment.title'),
                                unit: t('screens.product.sideBarProduct.chemicalProperties.cappingTreatment.unit'),
                            }
                        },
                        productDescription: t('screens.product.sideBarProduct.productDescription'),
                        utilization: t('screens.product.sideBarProduct.utilization'),
                        applications: t('screens.product.sideBarProduct.applications'),
                        buttonDownload: t('screens.product.sideBarProduct.buttonDownload'),
                        buttonSelect: t('screens.product.sideBarProduct.buttonSelect'),

                    },
                },

            },
            footerMainPage: {
                information: {
                    title: t('footerMainPage.information.title'),
                    subtitle: t('footerMainPage.information.subtitle'),
                    logo: "/assets/img/logo_company.png",
                },
                contactUs: {
                    title: t('footerMainPage.contactUs.title'),
                    address: t('footerMainPage.contactUs.address'),
                    phone: t('footerMainPage.contactUs.phone'),
                    email: t('footerMainPage.contactUs.email'),
                    whatsApp: t('footerMainPage.contactUs.whatsApp'),
                    site: {
                        text: "kheradazma.org"
                    }
                },
                aboutUs: {
                    title: t('footerMainPage.aboutUs.title'),
                    discription: t('footerMainPage.aboutUs.discription'),
                },
            },


        }
    )
}
