import { Theme, useTheme } from "@mui/material/styles";;

// Define SupportedMethods type as filter type of methods axios use case.
export enum SupportedMethods {
    get = "get",
    post = "post",
    put = "put",
    delete = "delete",
    patch = "patch"
};
export const baseUrl = "https://kheradazma-api.niktech.org"
// Put your new endpoint like here.
export const HandlerLangUrl = (url: string, mtheme: any) => {
    return (`${baseUrl}${url}`)
    // return (`${baseUrl}${mtheme.direction == "rtl" ? "/fa" : "/en"}${url}`)
}

export const urlsList = {
    auth: {
        login: { url: `/accounts/users/auth/login/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
        register: {
            generateOTP: { url: `/accounts/users/otp/generate/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
            validateOTP: { url: `/accounts/users/otp/validate/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
            setPassword: { url: `/accounts/users/password/set/`, method: SupportedMethods.put, isTokenNecessary: true, isFileInclude: false },
        },
        refreshToken: { url: `/accounts/users/auth/refresh/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },

    },
    product: {
        getProductList: { url: `/product/products/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
        getFilterList: { url: `/product/filters/`, method: SupportedMethods.get, isTokenNecessary: false, isFileInclude: false },
        postOrderProduct: { url: `/product/order/`, method: SupportedMethods.post, isTokenNecessary: false, isFileInclude: false },
    },
  
};