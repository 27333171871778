import {ReactNode, Children, Component} from "react";
import { useTranslation } from "react-i18next";

type HttpErrors = 404 | 500;
export const HttpError = ({code = 500}: { code?: HttpErrors }) => {
  const { t } = useTranslation();
      switch (code) {
        case 404:
          return (
              <div style={{
                width:"100vw",
                height:"100vh",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems:"center",
                textAlign:"center",
              }}>
                <h1>404 - {t("pageNotFound")}</h1>
              </div>
          );
        case 500:
        default:
          return (
              <div style={{
                width:"100vw",
                height:"100vh",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems:"center",
                textAlign:"center",
              }}>

                <h1>Something Went Wrong</h1>
              </div>
          );
      }
    }
;

export default HttpError;
