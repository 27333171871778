import { Link } from "react-router-dom";
import links from "../../../config/links";
import { ConfigComps } from "../../../config/ConfigCOMP";
import { styled as styledMUI, useTheme, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Button, ButtonGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { interfaceTraining } from "./interfaceTraining";
import colors from "../../../config/colors";
import React, { useState } from "react";
import { EnumTrainingTypeFile } from "../../../helper/Training/EnumTraining";
import { DownloadRounded, MovieRounded, PlayCircleRounded } from "@mui/icons-material";
import EmptyList from "../../../components/emptyList/EmptyList";
import DialogShowVideo from "./dialogShowVideo/DialogShowVideo";

const Header = styledMUI("div")(({ theme }) => ({
    background: "linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,1))",
    // background: "linear-gradient(to bottom, #3FB5A1, #1EE462)",
    width: "100%",
    color: colors.screens.color,
    paddingTop: "calc(7.5rem + 6vw)",
    paddingBottom: "calc(4rem + 3vw)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundPosition: "80% 0",
    backgroundSize: "cover",
    position: "relative"
}));
const ButtonGreen = styledMUI(Button)(({ theme }) => ({
    pointerEvents: "auto",
    borderRadius: "2em",
    padding: "0.5rem 3rem",
    marginBottom: "18px",
    textAlign: "center",
    border: "2px solid",
    fontSize: "14px",
    overflow: "hidden",
    position: "relative",
    zIndex: "2",
    color: "white",
    boxShadow: colors.shadow.boxShadowDarkInBottom,
    background: colors.theme.green.dark60,
    "& span": {
        marginRight: "8px",
    },
    '&:hover': {
        color: colors.green.dark2,
        boxShadow: "none",
        'div': {
            position: "absolute",
            top: "0",
            left: "0",
            width: "120%",
            height: "100%",
            background: "#fff",
            zIndex: "-1",
            transform: "rotate(0deg) translate3d(0, 0em, 0)",
        }
    }
}));
const ButtonBlue = styledMUI(Button)(({ theme }) => ({
    pointerEvents: "auto",
    borderRadius: "2em",
    padding: "0.5rem 3rem",
    margin: "0",
    textAlign: "center",
    fontSize: "14px",
    overflow: "hidden",
    position: "relative",
    zIndex: "2",
    color: "white",
    border: "2px solid",
    "& span": {
        marginRight: "8px",
    },
    boxShadow: colors.shadow.boxShadowDarkInBottom,
    background: colors.theme.blue.dark80,
    '&:hover': {
        color: colors.theme.blue.dark80,
        boxShadow: "none",
        'div': {
            position: "absolute",
            top: "0",
            left: "0",
            width: "120%",
            height: "100%",
            background: "#fff",
            zIndex: "-1",
            transform: "rotate(0deg) translate3d(0, 0em, 0)",
        }
    }
}));
const ShadowButtonProducts = styledMUI("div")(({ theme }) => ({

    position: "absolute",
    top: "0",
    left: "0",
    pointerEvents: "none",
    width: "120%",
    height: "101%",
    zIndex: "-1",
    transformOrigin: "0% 100%",
    transform: "rotate3d(0, 0, 1, -120deg) translate3d(0, -3em, 0)",
    transition: "transform 0.3s, opacity 0.3s, background-color 0.3s",
}));
const CategoryButton = styledMUI(
    "div", { shouldForwardProp: (prop) => prop !== 'isSelected' })
    <{ isSelected: boolean; }>(({
        theme,
        isSelected
    }) => ({
        color: isSelected ? colors.theme.green.dark20 : "#525252",
        borderBottom: isSelected ? "3px solid" : undefined,
        fontSize: "1.2rem",
        fontWeight: "600",
        paddingBottom: "8px",
        cursor: "pointer",
        ':hover': {
            color: isSelected ? colors.theme.green.dark20 : "black",
        }

    }));
const ImageOfBackgroundHeader = styledMUI("div")(({ theme }) => ({
    display: "block",
    width: "100%",
    height: "100%",
    zIndex: "1",
    opacity: "0.3",
    // background: `url(${ConfigComps().screens.product.accessories.header.backgroundImage})`,
    background: "url(/assets/img/background_training.jpeg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "80% 0",
    backgroundSize: "cover",
    position: "absolute",
    top: 0,
    right: 0,
}))
const Training = (pageParams: interfaceTraining) => {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [getSelectedCategory, setSelectedCategory] = React.useState<string>(confComps.screens.training.main.category[0].title);
    const [getParamsOfDialog, setParamsOfDialog] = useState<{ title: string, link: string } | undefined>(undefined);
    const handleCallbackCloseDialogVideo = () => {
        setParamsOfDialog(undefined);
    }
    const onClickVideo = (linkFile: string, title: string) => {
        setParamsOfDialog({
            title: title,
            link: linkFile,
        });
    }

    const onClickDownloadFile = (linkFile: string, filename: string) => {
        // const blob = new Blob([content], { type: 'text/plain' }); // Adjust type for different files
        // const url = window.URL.createObjectURL();
        const link = document.createElement('a');
        link.href = linkFile;
        link.target = "_blank";
        link.setAttribute('download', filename);
        link.style.display = 'none'; // Hide the link
        document.body.appendChild(link);
        link.click();
        // Revoke the object URL to avoid memory leaks
        link.parentNode?.removeChild(link);

    }
    return (
        <div style={{
            width: "100%",
            height: "auto",
            overflow: "auto",
            display: "flex",
            // paddingTop: "90px",
            flexDirection: "column",
            justifyContent: "start",
            alignContent: "start",
            alignItems: "start",
            textAlign: "center",
            background: "#FFF",
            // maxWidth: "1400px"
        }}>
            <Header>
                <ImageOfBackgroundHeader />
                <h1 style={{ zIndex: "3" }}>{confComps.screens.training.main.header}</h1>
                <div style={{
                    display: "flex", flexDirection: "row",
                    alignItems: "center", justifyContent: "center",
                    gap: "1rem",
                    color: colors.theme.green.dark20,
                    fontSize: "1.2rem",
                    zIndex: "3",
                    fontWeight: "900",
                    paddingTop: "calc(8px + 1rem)"
                }}>

                    <Link to={`${links.wordpressLink.mainPage}?lang=${mTheme.direction == "rtl" ? "fa" : "en"}`}
                        style={{
                            textDecoration: "blink",
                            color: colors.theme.green.dark20,
                            outline: "none",
                        }}>
                        {confComps.screens.training.main.subHeader.linked[0]}
                    </Link>
                    <div style={{
                        color: colors.theme.green.dark20,
                    }}>
                        /
                    </div>
                    <Link to={""}
                        style={{
                            textDecoration: "blink",
                            color: colors.theme.green.dark20,
                            outline: "none"
                        }}>
                        {confComps.screens.training.main.subHeader.linked[1]}
                    </Link>
                </div>
            </Header >
            <br />
            <div style={{
                width: "100%", display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                flexWrap: "wrap"
            }}>



                {confComps.screens.training.main.category.map((item, index, arr) => {
                    return (
                        <CategoryButton isSelected={getSelectedCategory == item.title}
                            onClick={() => {
                                setSelectedCategory(item.title)
                            }}>
                            {item.title}
                        </CategoryButton>

                    );
                })}
            </div>
            <div style={{
                width: "100%", display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                boxSizing: "border-box",
                paddingInline: "max(3vw , 12px)",
                marginTop: "max(4vh , 24px)",
                gap: "calc(12px + 1vh)",
                boxShadow: colors.shadow.boxShadowDarkInBottom,
                marginBottom: "2rem",
            }}>
                {
                    confComps.screens.training.main.items.array.filter(item => (item.ownerCategory ?? []).filter(cat => String(cat) === getSelectedCategory).length > 0)
                        .flatMap((train, index, array) => {
                            return (
                                [
                                    <div style={{
                                        width: "100%", display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}>
                                        <h6>
                                            {` ${index + 1}.  `}
                                            {train.name}
                                        </h6>
                                        {index % 2 == 0 ?
                                            <ButtonBlue
                                                endIcon={train.type == EnumTrainingTypeFile.file ?
                                                    <DownloadRounded />
                                                    : <PlayCircleRounded />
                                                }
                                                onClick={() => {
                                                    if (train.type == EnumTrainingTypeFile.file) {
                                                        onClickDownloadFile(train.link, train.name)
                                                    }
                                                    else {
                                                        onClickVideo(train.link, train.name)
                                                    }
                                                }}
                                                style={{ width: "11rem" }}>
                                                <ShadowButtonProducts />
                                                {train.type == EnumTrainingTypeFile.file ?
                                                    confComps.screens.training.main.items.buttonDoc.title :
                                                    confComps.screens.training.main.items.buttonVideo.title
                                                }

                                            </ButtonBlue>
                                            :
                                            <ButtonGreen
                                                endIcon={train.type == EnumTrainingTypeFile.file ?
                                                    <DownloadRounded />
                                                    : <PlayCircleRounded />
                                                }
                                                onClick={() => {
                                                    if (train.type == EnumTrainingTypeFile.file) {
                                                        onClickDownloadFile(train.link, train.name)
                                                    }
                                                    else {
                                                        onClickVideo(train.link, train.name)
                                                    }
                                                }}
                                                style={{ width: "11rem" }}>
                                                <ShadowButtonProducts />
                                                {train.type == EnumTrainingTypeFile.file ?
                                                    confComps.screens.training.main.items.buttonDoc.title :
                                                    confComps.screens.training.main.items.buttonVideo.title
                                                }
                                            </ButtonGreen>


                                        }
                                    </div>,


                                    < div style={{
                                        width: "100%",
                                        paddingInline: "2vw",
                                        boxSizing: "border-box",
                                    }} >
                                        {index != (array.length - 1) ?
                                            <div style={{
                                                border: "1px dashed #e0e0e0", width: "100%",
                                                boxSizing: "border-box", color: "#e0e0e0"
                                            }} />
                                            : undefined
                                        }
                                    </div>

                                ]
                            )
                        }
                        )
                }
                {
                    confComps.screens.training.main.items.array.filter(item => (item.ownerCategory ?? []).filter(cat => String(cat) === getSelectedCategory).length > 0)
                        .length == 0 ?
                        <EmptyList text={confComps.screens.training.main.items.empty} /> :
                        undefined
                }

            </div>
            <DialogShowVideo isOpen={getParamsOfDialog != undefined}

                typeOfDisplay={pageParams.typeOfDisplay}
                callbackOnCloseDialog={handleCallbackCloseDialogVideo}
                link={getParamsOfDialog?.link ?? ""}
                title={getParamsOfDialog?.title ?? ""}
            />
        </div >
    )
}
export default Training;