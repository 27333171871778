import * as React from 'react';
import { styled as styledMUI, styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import colors from "../../../config/colors";
import { Button, Drawer, IconButton, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import {
    AccountBalanceWalletRounded, AccountBalanceWalletSharp,
    AddRounded,
    CancelOutlined,
    CancelRounded,
    ForumRounded,
    ForumSharp,
    HomeRounded,
    MinimizeRounded,
    PowerSettingsNewRounded,
    RemoveRounded
} from "@mui/icons-material";
import { CSSProperties } from "@mui/material/styles/createTypography";
import links from "../../../config/links";
import { Link } from "react-router-dom";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";
import { useEffect, useState } from "react";
import { HelperResponsive } from "../../../helper/Helper";
import InterfaceSideBarProductDetails from './InterfaceSideBarProductDetails';
import { ConfigComps } from '../../../config/ConfigCOMP';
import { urlsList, baseUrl } from '../../../api/UrlsList';





const styleOfText: CSSProperties = {
    textAlign: "start",
    textDecoration: "none"
}
const styleOfIcon: CSSProperties = {
    justifyContent: "center"
}
const CustomDrawer = styledMUI(Drawer)(({ theme }) => ({
    "& .MuiPaper-elevation": {
        borderEndEndRadius: "10px",

        [theme.breakpoints.up('md')]: {
            zIndex: 999,
            boxShadow: theme.direction == "rtl" ?
                "-2px 2px 1px -1px rgb(0 0 0 / 20%), -1px 1px 1px 0px rgb(0 0 0 / 14%), -1px 1px 3px 0px rgb(0 0 0 / 12%)" :
                " 1px 2px 1px -1px rgb(0 0 0 / 20%),  1px 1px 1px 0px rgb(0 0 0 / 14%),  1px 1px 3px 0px rgb(0 0 0 / 12%)",
            height: "-webkit-fill-available",
        }
    },
    "& .MuiPaper-root": {
        left: theme.direction == "rtl" ? "unset" : "0",
        right: theme.direction == "rtl" ? "0" : "unset",
    }


}));
const CustomListItemButton = styled(ListItemButton)((theme) => ({
    borderRadius: "5px",
    textAlign: "right",
    "&.Mui-selected, &.Mui-selected:hover": {
        // color: colors.hamburgerMenu.textSelected,
        // backgroundColor: colors.hamburgerMenu.ButtonSelected
    }


}));
const LogoContainer = styledMUI("div")(({ theme }) => ({
    fontsize: "32px",
    display: "flex",
    justifyContent: "start",
    alignItems: "start",
    marginBlockStart: "4px",
    marginInlineEnd: "calc(4px + 1vw)",
}));
const TitleSection = styledMUI("h4")(({ theme }) => ({
    fontSize: "16px",
    color: colors.green.dark,
    fontWeight: "bold",
    margin: "45px 0 10px",
    lineHeight: "22px",
}));
const RowOfSection = styledMUI("p")(({ theme }) => ({
    borderBottom: `1px solid ${colors.screens.product.sideBarProduct.title}`,
    display: "flex",
    flexDirection: theme.direction == "rtl" ? "row-reverse" : "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    padding: "4px 0"
}));
const TitleOfRowOfSection = styledMUI("span")(({ theme }) => ({
    textAlign: "left",
    lineHeight: "26px",
    flexGrow: "1",
    fontSize: "16px",
    color: colors.screens.product.sideBarProduct.title
}));
const ExplainTextOfSection = styledMUI("p")(({ theme }) => ({
    fontSize: "18px",
    lineHeight: "26px",
    marginBottom: "15px",
}));
const UnitOfRowOfSection = styledMUI("span")(({ theme }) => ({
    position: "absolute",
    top: "-14px",
    right: "0rem",
    lineHeight: "26px",
    width: "100px",
    fontSize: "12px",
    color: colors.screens.product.sideBarProduct.title
}));
const BoxValueAndUnit = styledMUI("div")(({ theme }) => ({
    position: "relative",
    paddingRight: "1.2rem",

}))
const ShadowButtonProducts = styledMUI("div")(({ theme }) => ({

    position: "absolute",
    top: "0",
    left: "0",
    pointerEvents: "none",
    background: "blue",
    width: "120%",
    height: "101%",
    zIndex: "-1",
    transformOrigin: "0% 100%",
    transform: "rotate3d(0, 0, 1, -120deg) translate3d(0, -3em, 0)",
    transition: "transform 0.3s, opacity 0.3s, background-color 0.3s",
}));
const ButtonSelected = styledMUI(Button, { shouldForwardProp: (prop) => prop !== 'isSelected' })<{ isSelected: boolean; }>(({
    theme,
    isSelected
}) => {
    return ({
        width: "100%",
        color: isSelected ? colors.screens.product.basketShop.remove : colors.green.dark2,
        pointerEvents: "auto",
        borderRadius: "2em",
        padding: "0.5rem 3rem",
        marginBottom: "18px",
        textAlign: "center",
        border: "1px solid",
        fontSize: "14px",
        overflow: "hidden",
        position: "relative",
        zIndex: "2",
        '&:hover': {
            color: "white",
            'div': {
                position: "absolute",
                top: "0",
                left: "0",
                width: "120%",
                height: "100%",
                background: isSelected ? colors.screens.product.basketShop.remove : colors.green.dark2,
                zIndex: "-1",
                transform: "rotate(0deg) translate3d(0, 0em, 0)",
            }
        }
    })
});
const ButtonProducts = styledMUI(Button)(({ theme }) => ({
    width: "100%",
    color: colors.green.dark2,
    pointerEvents: "auto",
    borderRadius: "2em",
    padding: "0.5rem 3rem",
    marginBottom: "18px",
    textAlign: "center",
    border: "1px solid",
    fontSize: "14px",
    overflow: "hidden",
    position: "relative",
    zIndex: "2",
    '&:hover': {
        color: "white",
        'div': {
            position: "absolute",
            top: "0",
            left: "0",
            width: "120%",
            height: "100%",
            background: colors.green.dark2,
            zIndex: "-1",
            transform: "rotate(0deg) translate3d(0, 0em, 0)",
        }
    }
}));


function SideBarProductDetails(paramsComponent: InterfaceSideBarProductDetails) {
    const mTheme = useTheme();
    const [isProductSelected, setIsProductSelected] = useState<boolean>(true);
    useEffect(() => {
        setIsProductSelected(paramsComponent.productPropertis?.isSelected ?? false)
    }, [paramsComponent.productPropertis?.isSelected]);
    var confComps = ConfigComps();
    const toggleDrawer = (open: boolean) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (paramsComponent.callBackOnClose) {
            paramsComponent.callBackOnClose();
        }
    }


    const isLocationContainLink = (url: string) => {
        if (window.location.href.toLowerCase().includes(url.toLowerCase())) {
            return true;
        }
        return false;
    };
    return (
        <CustomDrawer

            variant={(paramsComponent.isHamburgerMenuStaticAndFixPosition) ? "permanent" : "temporary"}
            //from which side the drawer slides in
            anchor={mTheme.direction == "rtl" ? "left" : "left"}
            //if open is true --> drawer is shown
            open={paramsComponent.isHamburgerMenuShow}
            //function that is called when the drawer should close
            onClose={toggleDrawer(false)}
            style={{
                paddingTop: (paramsComponent.isHamburgerMenuStaticAndFixPosition) ? "40px" : undefined,
                direction: mTheme.direction
            }}
            dir={mTheme.direction}
        //function that is called when the drawer should open
        // onOpen={toggleDrawer(true)}
        >
            {/* The inside of the drawer */}
            <div
                style={{
                    width: paramsComponent.typeOfDisplay > enumListSizeDevices.tablet ? "438px" : undefined,
                    height: "100vh", overflow: "scroll"
                }}
            >
                <div style={{ padding: "0 50px", overflow: "auto", maxHeight: "100vh" }}>

                    <div style={{
                        position: "sticky", top: "0", "width": "100%", padding: "50px 0 0", background: "white",
                        zIndex: "100"
                    }}
                    >
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                            <span style={{ color: colors.screens.product.sideBarProduct.title, fontSize: "27px", fontWeight: "500", lineHeight: "22px" }}>
                                {confComps.screens.product.sideBarProduct.headerGroup.title}
                            </span>
                            <IconButton onClick={() => {
                                if (paramsComponent.callBackOnClose)
                                    paramsComponent.callBackOnClose()
                            }}>
                                <CancelOutlined

                                    style={{
                                        color: colors.green.dark,
                                        cursor: "pointer",
                                        width: "32px", height: "32px",
                                        fontSize: "27px",
                                        transition: ".2s all ease"
                                    }} />
                            </IconButton>

                        </div>
                        <h5
                            style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "22px"
                            }}
                        >{confComps.screens.product.sideBarProduct.headerGroup.subtitle}</h5>
                        <h3 style={{ marginTop: "22px" }}>
                            {paramsComponent.productPropertis?.product_description}
                        </h3>
                        <div style={{
                            width: "100%",
                            display: "block",
                            position: "relative",
                            height: "20px",
                            top: "20px",
                            backgroundImage: "linear-gradient(white, rgba(255, 255, 255, 0))",
                        }}>

                        </div>
                    </div>
                    <TitleSection>
                        {confComps.screens.product.sideBarProduct.physicalPropertiesForBareSilica.title}
                    </TitleSection>
                    <RowOfSection>
                        <TitleOfRowOfSection>
                            {confComps.screens.product.sideBarProduct.physicalPropertiesForBareSilica.particuleSize.title}
                        </TitleOfRowOfSection>
                        <BoxValueAndUnit>
                            <TitleOfRowOfSection>
                                {paramsComponent.productPropertis?.particle_size_label}
                            </TitleOfRowOfSection>
                            <UnitOfRowOfSection>
                                {confComps.screens.product.sideBarProduct.physicalPropertiesForBareSilica.particuleSize.unit}
                            </UnitOfRowOfSection>
                        </BoxValueAndUnit>

                    </RowOfSection>
                    <RowOfSection>
                        <TitleOfRowOfSection>
                            {confComps.screens.product.sideBarProduct.physicalPropertiesForBareSilica.poreDiameter.title}
                        </TitleOfRowOfSection>
                        <BoxValueAndUnit>
                            <TitleOfRowOfSection>
                                {paramsComponent.productPropertis?.pore_diameter}
                            </TitleOfRowOfSection>
                            <UnitOfRowOfSection>
                                {confComps.screens.product.sideBarProduct.physicalPropertiesForBareSilica.poreDiameter.unit}
                            </UnitOfRowOfSection>
                        </BoxValueAndUnit>
                    </RowOfSection>
                    <RowOfSection>
                        <TitleOfRowOfSection>
                            {confComps.screens.product.sideBarProduct.physicalPropertiesForBareSilica.surfaceArea.title}
                        </TitleOfRowOfSection>
                        <BoxValueAndUnit>
                            <TitleOfRowOfSection>
                                {paramsComponent.productPropertis?.surface_area}
                            </TitleOfRowOfSection>
                            <UnitOfRowOfSection>
                                m
                                <sup>2</sup>
                                /g
                            </UnitOfRowOfSection>
                        </BoxValueAndUnit>
                    </RowOfSection>
                    <RowOfSection>
                        <TitleOfRowOfSection>
                            {confComps.screens.product.sideBarProduct.physicalPropertiesForBareSilica.length.title}
                        </TitleOfRowOfSection>
                        <BoxValueAndUnit>
                            <TitleOfRowOfSection>
                                {paramsComponent.productPropertis?.length}
                            </TitleOfRowOfSection>
                            <UnitOfRowOfSection>
                                {confComps.screens.product.sideBarProduct.physicalPropertiesForBareSilica.length.unit}
                            </UnitOfRowOfSection>
                        </BoxValueAndUnit>
                    </RowOfSection>
                    <RowOfSection>
                        <TitleOfRowOfSection>
                            {confComps.screens.product.sideBarProduct.physicalPropertiesForBareSilica.innerDiameter.title}
                        </TitleOfRowOfSection>
                        <BoxValueAndUnit>
                            <TitleOfRowOfSection>
                                {paramsComponent.productPropertis?.inner_diameter_label}
                            </TitleOfRowOfSection>
                            <UnitOfRowOfSection>
                                {confComps.screens.product.sideBarProduct.physicalPropertiesForBareSilica.innerDiameter.unit}
                            </UnitOfRowOfSection>
                        </BoxValueAndUnit>
                    </RowOfSection>
                    <TitleSection>
                        {confComps.screens.product.sideBarProduct.chemicalProperties.title}
                    </TitleSection>
                    <RowOfSection>
                        <TitleOfRowOfSection>
                            {confComps.screens.product.sideBarProduct.chemicalProperties.siO2.title}
                            <sub>2</sub>
                        </TitleOfRowOfSection>
                        <BoxValueAndUnit>
                            <TitleOfRowOfSection>
                                {paramsComponent.productPropertis?.sio2}
                                {confComps.screens.product.sideBarProduct.chemicalProperties.siO2.unit}
                            </TitleOfRowOfSection>
                            {/* <UnitOfRowOfSection>
                            {confComps.screens.product.sideBarProduct.chemicalProperties.siO2.unit}
                        </UnitOfRowOfSection> */}
                        </BoxValueAndUnit>
                    </RowOfSection>
                    <RowOfSection>
                        <TitleOfRowOfSection>
                            {confComps.screens.product.sideBarProduct.chemicalProperties.bonding.title}
                        </TitleOfRowOfSection>
                        <BoxValueAndUnit>
                            <TitleOfRowOfSection>
                                {paramsComponent.productPropertis?.bonding}
                            </TitleOfRowOfSection>
                            <UnitOfRowOfSection>
                                {confComps.screens.product.sideBarProduct.chemicalProperties.bonding.unit}
                            </UnitOfRowOfSection>
                        </BoxValueAndUnit>
                    </RowOfSection>
                    <RowOfSection>
                        <TitleOfRowOfSection>
                            {confComps.screens.product.sideBarProduct.chemicalProperties.cappingTreatment.title}
                        </TitleOfRowOfSection>
                        <BoxValueAndUnit>
                            <TitleOfRowOfSection>
                                {paramsComponent.productPropertis?.capping_treatment}
                            </TitleOfRowOfSection>
                            <UnitOfRowOfSection>
                                {confComps.screens.product.sideBarProduct.chemicalProperties.cappingTreatment.unit}
                            </UnitOfRowOfSection>
                        </BoxValueAndUnit>
                    </RowOfSection>
                    <TitleSection>
                        {confComps.screens.product.sideBarProduct.productDescription}
                    </TitleSection>
                    <ExplainTextOfSection>
                        {paramsComponent.productPropertis?.product_description}
                    </ExplainTextOfSection>
                    <TitleSection>
                        {confComps.screens.product.sideBarProduct.utilization}
                    </TitleSection>
                    <ExplainTextOfSection>
                        {paramsComponent.productPropertis?.utilization}
                    </ExplainTextOfSection>
                    <TitleSection>
                        {confComps.screens.product.sideBarProduct.applications}
                    </TitleSection>
                    <ExplainTextOfSection>
                        {paramsComponent.productPropertis?.applications}
                    </ExplainTextOfSection>
                    <Link to={`${baseUrl}/${paramsComponent.productPropertis?.pdf_catalog}`} target='_blank'>
                        <ButtonProducts>
                            <ShadowButtonProducts />
                            {confComps.screens.product.sideBarProduct.buttonDownload}
                        </ButtonProducts>
                    </Link>
                    <ButtonSelected onClick={() => {
                        paramsComponent.callbackSelectProduct(paramsComponent.productPropertis?.id ?? 0);

                    }}
                        style={{ alignItems: "start" }}
                        isSelected={isProductSelected}>
                        <ShadowButtonProducts />
                        {isProductSelected == true ? <RemoveRounded /> : <AddRounded />}

                        {confComps.screens.product.sideBarProduct.buttonSelect}
                    </ButtonSelected>
                </div>


            </div>

        </CustomDrawer>
    );
}


export default SideBarProductDetails;