import { Dialog, IconButton, useTheme } from "@mui/material";
import { interfaceDialogShowVideo } from "./interfaceDialogShowVideo";
import { useEffect, useState } from "react";
import { ConfigComps } from "../../../../config/ConfigCOMP";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SelectedProfile } from "../../../../store/profileStateSlice";
import { CancelRounded } from "@mui/icons-material";

const DialogShowVideo = (dialogParams: interfaceDialogShowVideo) => {
    const [getOpen, setOpen] = useState<boolean>(dialogParams.isOpen);
    const [getValueEmount, setValueEmount] = useState<string>("");
    const confComps = ConfigComps();
    const mTheme = useTheme();
    const navigate = useNavigate();
    const profileInStore = useSelector(SelectedProfile);
    const dispatch = useDispatch();
    useEffect(() => {
        setOpen(dialogParams.isOpen)
    }, [dialogParams.isOpen])
    const handleClose = () => {
        setOpen(false);
        dialogParams.callbackOnCloseDialog();
    };
    const handleOnclickCancelDialog = () => {
        dialogParams.callbackOnCloseDialog();
    }
    return (
        <Dialog onClose={handleClose} open={getOpen} dir={mTheme.direction}
            style={{ padding: "calc(16px) calc(16px)" }}
            maxWidth={false}

        >
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "start" }}>
                <h3 style={{ margin: "10px calc(16px + 2vw) 10px calc(16px + 2vw)", padding: "0 8px" }}>{dialogParams.title}</h3>
                <IconButton
                    onClick={handleOnclickCancelDialog}>
                    <CancelRounded />
                </IconButton>
            </div>
            <div style={{ padding: "0 calc(16px + 2vw) 24px calc(16px + 2vw)", marginTop: "0px" }}>

                <div style={{ display: "flex", flexDirection: "row", gap: "calc( 1vw + 8px)", margin: "calc(8px + 1vh) calc(8px)", justifyContent: "start", alignItems: "start" }}>
                    {/* <h6>{dialogParams.title}</h6> */}
                    <p>{dialogParams.description}</p>
                </div>

                {
                    <video width={"100%"} controls controlsList="nodownload" >
                        <source src={dialogParams.link} type="video/mp4" />
                    </video>
                }



            </div>
        </Dialog>
    );
}
export default DialogShowVideo;