import { styled as styledMUI } from "@mui/material/styles";
import { TextField } from "@mui/material";
import colors from "../../config/colors";

export const TextFieldFromMUI = styledMUI(TextField, { shouldForwardProp: (prop) => prop !== 'isValueMustLtr' && prop !== 'isSmallHintShow' && prop !== 'isHintTextCenter' && prop !== 'backgroundColor' })<{ isHintTextCenter: boolean, isValueMustLtr: boolean; isSmallHintShow?: boolean,backgroundColor?:string }>(({
    theme,
    isValueMustLtr,
    isSmallHintShow,
    isHintTextCenter,
    backgroundColor
}) => {
    let isSpanMustShow = isSmallHintShow ?? true;
    let diraction = isValueMustLtr ? "ltr" : theme.direction;
    let background = backgroundColor??undefined
    return (
        {
            margin: "8px 0px 0px 0px",
            flex: 1,
            // background: colors.screens.contactus.backgroundColorEditText,
            // color: colors.screens.aboutUs.imageAndContent.headerText,

            // "& div.MuiOutlinedInput-root": {
            //     background: colors.screens.contactus.backgroundColorEditText,
            // },
            "& input, textarea": {
                direction: diraction,
            },

            "& label.MuiFormLabel-root": {
                direction: diraction,
                right: diraction == "rtl" ? 0 : "unset",
                left:isHintTextCenter?"50%":undefined,
                top: "50%",
                transform: isHintTextCenter ? (`translate(${diraction == "rtl" ? "50%" : "-50%"}, -50%)`) :
                    (`translate(${diraction == "rtl" ? "-8px" : "8px"}, -50%)`),
            },

            "& label.MuiInputLabel-shrink": {

                right: diraction == "rtl" ? "0" : "unset",
                left: diraction == "rtl" ? "unset" : "0",
                top: "0",
                transform: `scale(0.75) translate(${diraction == "ltr" ? "calc(5px + 50%)" : "calc(-5px + -26%)"}, -50%)`,
                display: isSpanMustShow ? undefined : "none",

            },
            "& legend": {
                textAlign: diraction == "ltr" ? "left" : "right",
                "& span": {
                    display: isSpanMustShow ? undefined : "none",
                }

            },
            "& div.MuiInputBase-root":{
                background:background
            }


        })
});