import { Link } from "react-router-dom";
import links from "../../config/links";
import { ConfigComps } from "../../config/ConfigCOMP";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { interfaceHome } from "./interfaceHome";
import colors from "../../config/colors";

const Home = (propsFooter: interfaceHome) => {
    var confComps = ConfigComps();
    const mTheme = useTheme();
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    return (
        <div style={{
            width: "100%", display: "flex",
            flexDirection: "column",
            alignItems: "center", justifyContent: "center",
            gap: "2vw",
            marginTop: "12vh",
            background: "#4ea38e"
        }}>
            <Link to={links.product.FPLC} style={{ color: "white", }}>
                {"product/FPLC"}
            </Link>
            <Link to={links.product.Mass_Spectrometry} style={{ color: "white", }}>
                {"product/Mass"}
            </Link>
            <Link to={links.product.HPLC} style={{ color: "white", }}>
                {"product/HPLC"}
            </Link>
            <Link to={links.product.accessories} style={{ color: "white", }}>
                {"product/Accessories"}
            </Link>
            <Link to={links.training.main} style={{ color: "white", }}>
                {"training/main"}
            </Link>
        </div>
    )
}
export default Home;