const colors = {
    theme: {
        blue: {
            dark80: "#00509f",
        },
        green: {
            dark20: "#5ec3ae",
            dark40: "#4ea38e",
            dark60: "#3e706e",
        },
        pink: {
            dark40: "#CE93D8",
        }
    },
    green: {
        logo: "#5ec3ae",
        dark: "#4ea38e",
        dark2: "#3e706e",
    },
    shadow: {
        boxShadowDarkInBottom: `0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1)`,
        boxShadowDarkInTop: `0 -4px 8px 0px rgba(0, 0, 0, 0.2), 0 -6px 20px 0 rgba(0, 0, 0, 0.19)`,
        boxShadowDarkInTopAndBottom: `0 0px 8px 0px rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19)`,
    },
    Navbar: {
        backgroundColor: "#fefefe",

        // backgroundColor:"#fefefe",
        // bottomBorder: "#606fc7",
        bottomBorder: "#5ec3ae",
        color: "#303030"
    },
    screens: {
        color: "#fefefe",
        border: "#3F51B5",
        product: {
            header: {

            },
            basketShop: {
                remove: "#CE93D8",
                backgroundCell: "#F5F5F5",
                color: "#CE93D8"
            },
            sideBarProduct: {
                title: "#39393A"
            },
            main: {
                tableProductSelected: {
                    form: {
                        button: {
                            background: "#4ea38e",//"#3F51B5",
                            hoverBackground: "#3F51B5",//"#CE93D8",
                            color: "#fff",
                        }
                    }
                },
                footer: {
                    background: "linear-gradient(to bottom, #3F51B5, #1E8CE4)",
                }
            },
        }
    },
    footer: {
        textColor: "#000",
    }
}
export default colors;