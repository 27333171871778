import { useState } from "react";
import { CardFromMUI } from "../../MUIConfig/CardFromMUI";
import { Button, ButtonBase, CardContent, Divider, FormControl, InputLabel, Theme } from "@mui/material";
import colors from "../../../config/colors";
import { enumListSizeDevices } from "../../../config/responsive/configResponsive";
import Box from "@mui/material/Box";

import { ConfigComps } from "../../../config/ConfigCOMP";
import { CallbackProductTableItemChanged } from "./InterfaceProductTableItem";
import { styled as styledMUI } from "@mui/material/styles";
import { AddRounded, BookRounded, DeleteOutlined, LibraryBooksRounded, MinimizeRounded, PanoramaFishEyeOutlined, RemoveRounded, VisibilityOutlined } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import links from "../../../config/links";
import { InterfacePropertisSideBarProductDetails } from "../../AppBarMenu/sideBarProductDetails/InterfaceSideBarProductDetails";
import { interfaceProductForList } from "../../../screens/product/InterfaceSelectFeatures";

const TitleP = styledMUI("p")(({ theme }) => ({
    whiteSpace: "nowrap",
    margin: "8px",
}));
const StateProcess = styledMUI(Button)(({
    theme,
}) => ({
    cursor: 'unset',
    whiteSpace: "nowrap",
    margin: "8px",
    boxShadow: "unset",
    ": hover": {
        // boxShadow: "0px 3px 1px -2px rgb(0 0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 0 / 12%)",
        boxShadow: "unset",
    }
}));
const CellTD = styledMUI("td", { shouldForwardProp: (prop) => prop !== 'isBorderActive' })<{ isBorderActive: boolean; }>(({
    theme,
    isBorderActive,
}) => ({
    border: isBorderActive ? "1px solid #d2d2d2" : undefined,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: "lighter",
    fontSize: "12px",
    textAlign: "center",
}));


const ProductTableItem = (paramsPage: interfaceProductForList & CallbackProductTableItemChanged) => {
    var confComps = ConfigComps();
    return (
        <tr style={{
        }}>

            <CellTD isBorderActive={false} style={{ color: colors.green.dark }}>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    alignItems: "start",
                    justifyContent: "space-around"
                }}>
                    <ButtonBase onClick={() => { paramsPage.callbackSideBarClicked(paramsPage.id) }}>
                        <LibraryBooksRounded />
                    </ButtonBase>
                </div>
            </CellTD>
            <CellTD isBorderActive={false} style={{
                borderBottom: "1px solid #d2d2d2",
                background: paramsPage.isSelected ? colors.screens.product.basketShop.remove : colors.green.dark, color: colors.screens.color
            }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "space-around"
                }}>
                    <ButtonBase onClick={() => { paramsPage.callbackSelected(paramsPage.id) }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            height: "100%",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            {paramsPage.isSelected ?
                                <RemoveRounded /> :
                                <AddRounded />
                            }
                        </div>
                    </ButtonBase>
                </div>
            </CellTD>
            <CellTD isBorderActive={true} style={{
                background: paramsPage.isSelected ? colors.screens.product.basketShop.backgroundCell : undefined,
                color: paramsPage.isSelected ? colors.screens.product.basketShop.color : undefined
            }}>
                {paramsPage.product_description}
            </CellTD>
            <CellTD isBorderActive={true}
                style={{ background: paramsPage.isSelected ? colors.screens.product.basketShop.backgroundCell : undefined }}>
                {paramsPage.particle_size}
            </CellTD>
            <CellTD isBorderActive={true}
                style={{ background: paramsPage.isSelected ? colors.screens.product.basketShop.backgroundCell : undefined }}>
                {paramsPage.pore_size}
            </CellTD>
            <CellTD isBorderActive={true}
                style={{ background: paramsPage.isSelected ? colors.screens.product.basketShop.backgroundCell : undefined }}>
                {paramsPage.bonding}
            </CellTD>
            <CellTD isBorderActive={true}
                style={{ background: paramsPage.isSelected ? colors.screens.product.basketShop.backgroundCell : undefined }}>
                {paramsPage.length}
            </CellTD>
            <CellTD isBorderActive={true}
                style={{ background: paramsPage.isSelected ? colors.screens.product.basketShop.backgroundCell : undefined }}>
                {paramsPage.inner_diameter}
            </CellTD>
        </tr>
    );

}

export default ProductTableItem;