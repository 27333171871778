import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { useTranslation } from 'react-i18next';
import { createTheme, styled as styledMUI, styled, ThemeProvider, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes, useParams, useSearchParams, useLocation } from "react-router-dom";
import { AlertStructure, SelectedAlert } from './store/AlertStateModeSlice';
import { initialState, selectedTheme, ThemeStructure } from "./store/themeModeSlice";
import { SelectedProgressbarInRoot } from './store/ProgressbarInRootSlice';
import { enumListSizeDevices } from "./config/responsive/configResponsive";
import { HelperResponsive } from "./helper/Helper";
import { AlertPopup } from './components/MUIConfig/alert_popup/AlertPopup';
import { ProgressBarInRoots } from './components/MUIConfig/progressbar_in_root/ProgressBarInRoot';
import HttpError from './screens/error/ErrorHandler';
import { enumTypeOfAlert } from './components/MUIConfig/alert_popup/interfaceAlertPopup';
import links from './config/links';
import { NavbarMainPage } from "./components/navbarMain/NavbarMainPage";
import Footer from "./components/footer/Footer";
import { ConfigComps } from "./config/ConfigCOMP";
import SelectHPLC from './screens/product/HPLC/SelectHPLC';
import SelectFPLC from './screens/product/FPLC/SelectFPLC';
import SelectMassSpectrometry from './screens/product/massSpectrometry/SelectMassSpectrometry';
import SelectAccessories from './screens/product/accessories/SelectAccessories';
import { EnumSelectedSectionProduct } from './components/navbarMain/InterfaceNavbarMainPage';
import Home from './screens/home/Home';
import Training from './screens/training/main/Training';
const Container = styled("div")`
  background: white;
  display: flex;
  flex-direction: column;
`
function App() {
  const themeInStore = useSelector(selectedTheme);
  const alertStateInStore = useSelector(SelectedAlert);
  const progressbarInstore = useSelector(SelectedProgressbarInRoot);
  const { t, i18n } = useTranslation();
  const mainThem = createTheme({
    ...((themeInStore as any) as ThemeStructure).theme
  });
  // const [getSearchParamsPage, setSearchParamsPage] = useSearchParams();
  var confComps = ConfigComps();
  const [displayType, SetDisplayType] = useState<enumListSizeDevices>(0);
  const mTheme = useTheme();
  const setLangugeFromStore = async () => {
    // 
  }

  const AddNavbarAndFooter = (element: any, TypeOfDisplay: enumListSizeDevices, pageTypeProduct: EnumSelectedSectionProduct) => {
    return (
      <Container>
        <NavbarMainPage
          listTitle={confComps.navbarMainPage.linksTitles}
          typeOfDisplay={HelperResponsive()}



          SelectUrlSelectedProduct={pageTypeProduct} />
        {element}
        <Footer listTitleOfAccessFast={[]} />
      </Container>
    );

  }
  useEffect(() => {
    SetDisplayType(HelperResponsive());
    setLangugeFromStore();
  }, []);

  return (
    <ThemeProvider theme={mainThem}>
      <div dir={mainThem.direction} style={{ direction: mainThem.direction }}>
        <AlertPopup typeOfWarning={alertStateInStore.alerts.typeAlert ? Object.values(enumTypeOfAlert)[Object.values(enumTypeOfAlert).indexOf(alertStateInStore.alerts.typeAlert as unknown as enumTypeOfAlert)] : enumTypeOfAlert.error}
          text={alertStateInStore.alerts.text} lastTimeecondsUpdate={alertStateInStore.alerts.lastTimeecondsUpdate} />
        <ProgressBarInRoots isVisible={progressbarInstore.isVisibility} value={progressbarInstore.value} lastTimeMilisUpdate={progressbarInstore.lastTimeMilisUpdate} />
        <Router>
          <Routes>
            <Route path={links.training.main} element={AddNavbarAndFooter(<Training typeOfDisplay={displayType} />, displayType, EnumSelectedSectionProduct.none)} />
            <Route path={links.home} element={AddNavbarAndFooter(<Home typeOfDisplay={displayType} />, displayType, EnumSelectedSectionProduct.none)} />

            <Route path={links.product.HPLC} element={AddNavbarAndFooter(<SelectHPLC typeOfDisplay={displayType} />, displayType, EnumSelectedSectionProduct.HPLC)} />
            <Route path={links.product.FPLC} element={AddNavbarAndFooter(<SelectFPLC typeOfDisplay={displayType} />, displayType, EnumSelectedSectionProduct.FPLC)} />
            <Route path={links.product.Mass_Spectrometry} element={AddNavbarAndFooter(<SelectMassSpectrometry typeOfDisplay={displayType} />, displayType, EnumSelectedSectionProduct.MassSpectrometry)} />
            <Route path={links.product.accessories} element={AddNavbarAndFooter(<SelectAccessories typeOfDisplay={displayType} />, displayType, EnumSelectedSectionProduct.Accessories)} />



            {/* <Route path='/panel' element={<PrivateRedirectToLogin />}>
                            
                            <Route path="*" element={<HttpError code={404} />} />
                          </Route> */}

            <Route path="/404" element={<HttpError code={404} />} />
            <Route path="*" element={<HttpError code={404} />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
