import {styled as styledMUI} from "@mui/material/styles";
import {Button} from "@mui/material";
import colors from "../../config/colors";
import {ExtendButtonBase} from "@mui/material/ButtonBase";
import {ButtonTypeMap} from "@mui/material/Button/Button";

export const ButtonFromMUI = styledMUI(Button)(({theme}) => ({
    boxShadow: `2px 4px 4px`,
    ':hover': {
        boxShadow: `1px 2px 4px`,
    }
}));
